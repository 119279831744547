import {css} from '@emotion/react'
import {BORDER, BORDER_RADIUS_SMALL} from './config.mjs'

export const cssButton = css({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: '#6a737c',
    backgroundColor: '#fff',
    textDecoration: 'none',
    padding: '.5em .75em',
    border: BORDER,
    borderRadius: BORDER_RADIUS_SMALL,
    cursor: 'pointer',

    '&:hover': {
        color: '#525960',
        backgroundColor: '#f8f9f9',
    },
})

export const cssButtonActive = css({
    color: '#3b4045',
    backgroundColor: '#e3e6e8',
})

export const cssButtonPrimary = css({
    color: '#fff',
    backgroundColor: '#0a95ff',
})

export const cssButtonSecondary = css({
})

export const cssButtonGroup = css({
    '& :not(:first-child)': {
        borderLeft: 'none',
        borderTopLeftRadius: 0,
        borderBottomLeftRadius: 0,
    },

    '& :not(:last-child)': {
        borderTopRightRadius: 0,
        borderBottomRightRadius: 0,
    },
})
