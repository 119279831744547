import {css} from '@emotion/react'
import User from '@/components/User/User.jsx'
import ModifyTime from './ModifyTime.jsx'

import {
    BORDER,
    FONT_SIZE_SECONDARY,
    FONT_SIZE_TRIVIAL,
    GAP_MEDIUM,
    GAP_SMALL,
} from '@/styles/config.mjs'

const cssComment = css({
    display: 'grid',
    gridTemplateColumns: '24px 1fr 200px',
    alignItems: 'start',
    gap: GAP_MEDIUM,
    fontSize: FONT_SIZE_SECONDARY,
    padding: `${GAP_SMALL}px 0`,
    borderTop: BORDER,

    '&:first-child': {
        borderTop: 'none',
    },
})

const cssAuthorAvatar = css({
    gridArea: '1 / 1 / 3 / 2',
    justifySelf: 'center',
    fontSize: 16,
})

const cssAuthorName = css({
    gridArea: '1 / 2 / 2 / 3',
})

const cssModifyTime = css({
    gridArea: '1 / 3 / 2 / 4',
    fontSize: FONT_SIZE_TRIVIAL,
})

const cssContent = css({
    gridArea: '2 / 2 / 4 / 3',
    lineHeight: 1.4,
    whiteSpace: 'pre-wrap',
})

export default function Comment({data, ...props}) {
    return (
        <div
            css={cssComment}
            {...props}
        >
            <User
                css={cssAuthorAvatar}
                avatar={data.authorAvatar}
                userId={data.authorId}
            />

            <User
                css={cssAuthorName}
                name={data.authorName}
                userId={data.authorId}
            />

            <ModifyTime
                css={cssModifyTime}
                createTime={data.crtTime}
                modifyTime={data.uptTime}
            />

            <div css={cssContent}>{data.content}</div>
        </div>
    )
}
