import React, { useState, useRef } from 'react';
import { Button, Checkbox, Divider, Form, Input, Result, Space, Modal } from 'antd';
import useHttp from "@/hooks/useHttp.mjs";
import runAsync from "@/scripts/runAsync.mjs";
import ImgBackground from './login_background.jpg';
import favicon from '/favicon.png';
import useDocTitle from "@/hooks/useDocTitle.mjs";
import { useNavigate } from 'react-router-dom';
import { LockOutlined, MailOutlined, UserOutlined, MobileOutlined, SendOutlined, VerifiedOutlined } from "@ant-design/icons";

const NameInput = () => {
    const rules = [
        {
            required: true,
            message: '请输入用户名',
        },
        {
            min: 8,
            message: '用户名长度为8-30',
        },
        {
            max: 30,
            message: '用户名长度为8-30',
        },
    ];

    return (
        <Form.Item name="username" rules={rules} label="用户名">
            <Input size="large" placeholder="请输入用户名" prefix={<UserOutlined />} />
        </Form.Item>
    );
}

const MobileInput = () => {
    const ref = useRef({});
    const http = useHttp();
    const [ctime, setCtime] = useState();

    const rules1 = [
        {
            required: true,
            message: '请输入手机号',
        },
        {
            pattern: /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/,
            message: '手机号格式不正确',
        },
    ];

    const rules2 = [
        {
            required: true,
            message: '请输入验证码',
        },
    ];

    // 发送短信
    const sendSms = async () => {
        try {
            const phone = ref.current.phone;
            if (phone) {
                countdown();

                await runAsync(
                    async () => {
                        return await http.post(`/register/captcha?param=${phone}`, {});
                    }, {
                    error: { content: (err) => `发送失败: ${err.message}` },
                    loading: { show: false },
                    success: { show: false },
                });
            }
        } catch (err) {
            console.log(err);
        }
    }

    const countdown = () => {
        let count = 60;
        const siz = setInterval(() => {
            if (count <= -1) {
                clearInterval(siz);
                setCtime(null);
            } else {
                setCtime(count--);
            }
        }, 1000);
    }

    return (
        <Space>
            <Form.Item name="mobile" rules={rules1} label="手机号">
                <Input
                    size="large"
                    placeholder="请输入手机号"
                    style={{ width: '250px' }}
                    prefix={<MobileOutlined />}
                    onBlur={(e) => {
                        ref.current = {};
                        ref.current.phone = e.target.value;
                    }}
                />
            </Form.Item>

            <Form.Item name="captcha" rules={rules2} label="验证码">
                <Input size="large" prefix={<VerifiedOutlined />} />
            </Form.Item>

            <Form.Item name="verifyCode2" label="&nbsp;">
                {
                    ctime ?
                        <Button size="large">{ctime}</Button>
                        :
                        <Button size="large" icon={<SendOutlined />} onClick={sendSms} />
                }
            </Form.Item>
        </Space>
    );
}

const PwdInput = () => {
    const rules = [
        {
            required: true,
            message: '请输入密码',
        },
        // {
        //     min: 8,
        //     message: '密码长度为8-20',
        // },
        // {
        //     max: 20,
        //     message: '密码长度为8-20',
        // },
        {
            pattern: /^.*(?=.{8,16})(?=.*\d)(?=.*[A-Z]{1})(?=.*[a-z]{1,})(?=.*[!@#$%^&*?\(\)]).*$/,
            message: '密码需包含数字、大小写字母、特殊字符，长度8~16'
        }
    ];

    return (
        <Form.Item name="pwd" rules={rules} label='密码'>
            <Input.Password size="large" placeholder="密码需包含数字、大小写字母、特殊字符，长度8~16" prefix={<LockOutlined />} />
        </Form.Item>
    );
}

const PwdInput2 = () => {
    const rules = [
        {
            required: true,
            message: '请输入确认密码',
        },
        ({ getFieldValue }) => ({
            validator(rule, value) {
                if (!value || getFieldValue('pwd') === value) {
                    return Promise.resolve();
                }
                return Promise.reject("两次密码输入不一致");
            }
        })
    ];

    return (
        <Form.Item name="password2" rules={rules} label="确认密码">
            <Input.Password size="large" placeholder="请输入确认密码" prefix={<LockOutlined />} />
        </Form.Item>
    );
}

const EmailInput = () => {
    const rules = [
        {
            required: true,
            message: '请输入邮箱',
        },
        {
            pattern: /^[a-zA-Z0-9_.-]+@[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)*\.[a-zA-Z0-9]{2,6}$/,
            message: '邮箱格式不正确',
        },
        {
            max: 50,
            message: '邮箱长度为0-50',
        },
    ];

    return (
        <Form.Item name="email" rules={rules} label="邮箱">
            <Input
                prefix={<MailOutlined />}
                size="large"
                placeholder="请输入邮箱"
            />
        </Form.Item>
    );
}

// 注册协议
const Agreement = () => {
    const [visible, setVisible] = useState(false);

    const rules = [
        {
            validator: (_, value) => value ? Promise.resolve() : Promise.reject(new Error('请阅读并同意注册协议')),
        },
    ];

    // style={{ letterSpacing: '2px' }}

    const cssStyle = {
        letterSpacing: '2px'
    }

    return (
        <>
            <Form.Item name='agreement' valuePropName="checked" rules={rules}>
                <Checkbox>
                    我已经阅读并同意 <a href="javascript:void(0);" style={{ color: '#FE4066' }} onClick={() => setVisible(true)}>注册协议</a>
                </Checkbox>
            </Form.Item>

            <Modal visible={visible} onCancel={() => setVisible(false)} footer={[]}>
                <div style={{ textAlign: 'center', fontSize: '12pt', fontWeight: 'bold' }}>
                    软件地图SMAP 用户注册协议
                </div>
                <p />

                <div css={cssStyle}>
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    欢迎您使用软件地图SMAP软件及服务！软件地图是由南京和度软件科技有限公司（以下简称”和度软件“)研发的应用于软件工程领域的一套软件设计建模系统。
                    在您成为软件地图注册用户之前，请确认您已充分阅读、理解并接受本协议的全部内容，同意遵循本协议的所有约定。如您不同意本服务协议，您可以主动停止使用软件地图提供的服务；您一旦使用，即视为您已了解并完全同意本服务协议各项内容。
                </div>
                <p />

                <div css={cssStyle}>
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    一、协议的范围
                    本协议是您与和度软件关于您注册、登录、使用软件地图SMAP，以及使用本软件服务所订立的协议。
                    软件的运营管理权归和度软件所有。本协议的内容，包括但不限于以下与本服务、本协议相关的协议、规则、规范以及将来可能不断发布的关于本服务的相关协议、规则、规范等内容，前述内容一经正式发布，即为本协议不可分割的组成部分，与条款正文具有同等效力，您同样应当遵守。
                </div>
                <p />

                <div css={cssStyle}>
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    二、服务内容
                    注册用户在软件地图能够在线进行一站式软件设计建模，根据您购买的版本提供相应的服务内容。
                    您可以通过电脑以网页形式使用本服务，您应选择与本软件相匹配的终端、系统和浏览器等，否则，您可能无法正常使用本服务。
                </div>
                <p />

                <div css={cssStyle}>
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    三、账号注册、使用与保管
                    用户在注册账号时，应当按注册页面引导提供真实、准确、完整的个人资料，并须确保在上述注册信息发生变化时及时更新信息及确保更新信息的有效性和合法性。
                    不得以虚假信息或其他违法和不良信息进行注册。
                    您有责任维护您的帐户和密码的安全性，不得将账号、密码转让或出借予他人使用。
                    您的帐户只可以由您本人登录及使用，多人共享帐号是被禁止的。
                    您在使用本软件过程中应遵守所有可适用的法律法规及本协议、业务规则的规定，不得发生任何违法、违反本协议或侵犯第三方合法权益的行为；您应对您帐户项下的全部行为承担相应法律责任。
                    若违反相关法律法规和本协议各项规定，和度软件有权对该用户账号单方采取暂停使用、注销登记等措施，终止为用户提供服务，并保留追责的权利。
                </div>
                <p />

                <div css={cssStyle}>
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    四、软件的更新
                    为了增进用户体验、完善服务内容，和度软件将不断努力优化或开发软件地图新的功能，并为您不时提供软件更新。
                    为了改善用户体验，并保证服务的安全性和功能的一致性，和度软件有权不经向您特别通知而对软件进行更新，或者对软件的部分功能效果进行改变或限制。
                </div>
                <p />

                <div css={cssStyle}>
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    五、知识产权声明
                    和度软件是软件地图SMAP的知识产权权利人。本软件的著作权、商标权、专利权、商业秘密等知识产权，以及与本软件相关的所有信息内容均受中华人民共和国法律法规和相应的国际条约保护，和度软件依法享有上述相关知识产权。
                </div>
                <p />

                <div css={cssStyle}>
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    六、第三方提供的产品或服务
                    您在软件地图上使用第三方提供的产品或服务时，除遵守本协议约定外，还应遵守第三方的用户协议。和度软件和第三方对可能出现的纠纷在法律规定和约定的范围内各自承担责任。
                </div>
                <p />

                <div css={cssStyle}>
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    七、服务变更、中止或终止
                    和度软件有权自主决定经营策略,有权在无需通知您的情况下随时对本服务内容进行变更，以及中断、中止或终止本服务。若由此给您造成损失的，您同意放弃追究和度软件的责任。
                    本协议约定的其他中止或终止条件发生或实现的，和度软件有权随时中止或终止向您提供全部或部分本服务。
                    除本协议另有约定外，如本协议或本服务因为任何原因终止的，对于您的账号中的全部数据或您因使用本服务而存储在和度软件服务器中的数据等任何信息，和度软件可将该等信息删除，包括服务终止前您尚未完成的任何数据，您同意不追究和度软件的任何责任或不向和度软件主张任何权利。
                </div>
                <p />

                <div css={cssStyle}>
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    八、免责条款
                    为了向您提供更完善的服务，和度软件有权定期或不定期地对提供本服务的平台或相关设备进行检修、维护、升级等，此类情况可能会造成相关服务在合理时间内中断或暂停的，若由此给您造成损失的，您同意放弃追究和度软件的责任。
                    因不可抗力、黑客攻击、病毒、木马、恶意程序攻击、网络拥堵、系统不稳定、系统或设备故障、通讯故障、电力故障、第三方服务瑕疵等原因而导致的用户信息泄漏、服务中断及其他损失。
                    注册用户使用软件地图服务过程中导致和度软件或任何其他第三方产生损失的，用户应当承担全部责任。
                    注册用户使用软件地图服务而产生的任何其他后果。
                </div>
                <p />

                <div css={cssStyle}>
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    九、法律适用及争议解决
                    本注册协议适用国家法律，由本协议引起的或与本协议相关的任何争议，应提交和度软件所在地法院通过诉讼解决。
                </div>
            </Modal>
        </>
    )
}

const SubmitButton = (props) => {
    return (
        <Button
            htmlType="submit"
            type="primary"
            size="large"
            block
            style={{
                fontWeight: 'bold',
                borderColor: '#fe4066',
                background: '#fe4066',
            }}
            {...props}
        >
            立即注册
        </Button>
    );
}

const Register = ({ form, doRegister, loading, }) => {
    const navigate = useNavigate();
    return (
        <div className="right-panel">
            {/* <h1>注册信息</h1> */}
            <Form form={form} onFinish={doRegister} layout='vertical'>
                <NameInput />
                <MobileInput />
                <PwdInput />
                <PwdInput2 />
                <Agreement />
                <SubmitButton loading={loading} />
            </Form>
            <Divider style={{ marginTop: 12, marginBottom: 12 }}></Divider>
            <div style={{ display: 'flex', justifyContent: 'center', fontWeight: 'bold' }}>
                <a onClick={() => navigate('/login?r=%2F')}>使用已有账号登录 →</a>
            </div>
        </div>
    )
}

const RegisterSuccessResult = () => {
    const navigate = useNavigate();
    return (
        <div style={{ background: '#fff', borderRadius: 32, width: '500px' }}>
            <Result
                status="success"
                title="注册成功"
                // subTitle="您将会收到一封来自软件地图的确认邮件，题为“确认您的邮箱”，请点击邮件中的链接即可验证邮箱地址。"
                extra={[
                    <a href="" style={{ color: '#FE4066', fontWeight: 'bold' }} onClick={() => navigate('/login?r=%2F')}>马上去登录 →</a>
                ]}
            />
        </div>
    )
}

const RegisterPage = () => {
    useDocTitle('注册');
    const http = useHttp();
    const [form] = Form.useForm();
    const navigate = useNavigate();

    // 加载显示
    const [loading, setLoading] = useState(false);
    const [visible, setVisible] = useState(false);

    const doRegister = async () => {
        try {
            setLoading(true);
            let formField = await form.validateFields();
            await runAsync(
                async () => {
                    return await http.post(`/register`, formField)
                },
                {
                    error: { content: (err) => `注册失败: ${err.message}` },
                    loading: { show: true },
                    success: { show: false },
                }
            );
            setVisible(true)
        } catch (err) {
            console.error(err);
        } finally {
            setLoading(false);
        }
    }

    const css = {
        display: 'flex',
        flexDirection: 'column',
        height: '100vh',
        overflow: 'hidden',
        backgroundImage: `url(${ImgBackground})`,
        backgroundSize: 'cover',
        '& .container': {
            display: 'flex',
            flexDirection: 'column',
            flexGrow: 1,
            overflow: 'auto',
            backgroundColor: 'rgba(53, 48, 48, 0.7)',
            lineHeight: 1.5715,
            '& .header': {
                flexShrink: 0,
                fontSize: 40,
                fontWeight: 'bold',
                color: '#fff',
                padding: '32px 64px',
                display: 'flex',
                alignItems: 'center',
                '& img': {
                    height: 40,
                    marginRight: 10,
                }
            },
            '& .main': {
                flexGrow: 1,
                display: 'flex',
                alignItems: 'center',
                justifyContent: ' center',
                '& .right-panel': {
                    background: '#fff',
                    borderRadius: 32,
                    boxShadow: '0 16px 32px 0 rgb(0 0 0 / 8%)',
                    display: 'flex',
                    flexDirection: 'column',
                    padding: '32px 64px',
                    // width: 488,
                    width: 600,
                    '& h1': {
                        fontSize: 30,
                        fontWeight: 'bold',
                        marginBottom: 12
                    },
                    '& form .ant-form-item-explain-error': {
                        fontWeight: 'bold',
                    }
                }
            },
            '& .footer': {
                flexShrink: 0,
                color: 'rgba(255, 255, 255, 0.7)',
                padding: '32px 64px',
                '& a': {
                    color: 'rgba(255, 255, 255, 0.7)',
                },
                '& a:hover': {
                    color: 'rgba(255, 255, 255)',
                }
            },
        },
    }

    return (
        <div css={css}>
            <div className="container">
                <div className='header'>
                    <img
                        alt="logo"
                        src={favicon}
                    />
                    软件地图社区
                </div>
                <div className="main">
                    {
                        !visible && <Register form={form} doRegister={doRegister} loading={loading} />
                    }
                    {
                        visible && <RegisterSuccessResult />
                    }
                </div>
                <div className="footer">
                    <div>©2016-2023 Hopedove Software Co.,Ltd. All rights reserved.</div>
                    <Space>
                        <a href='https://beian.miit.gov.cn/#/Integrated/index' target='_blank'>苏ICP备15043145号</a>
                        <a href='LoginPage#' target='_blank'>隐私政策</a>
                        <a href='LoginPage#' target='_blank'>使用协议</a>
                    </Space>
                </div>
            </div>
        </div>
    );
}

export default RegisterPage;
