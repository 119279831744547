import InputText from '@/components/Form/InputText.jsx'
import Card from './Card.jsx'

export default function Title({value, onChange, ...props}) {
    return (
        <Card
            title="标题"
            subTitle="标题应具体并概括问题"
            {...props}
        >
            <InputText
                placeholder="比如：怎样创建界面地图？"
                value={value}
                onChange={onChange}
            />
        </Card>
    )
}
