import {Outlet, useLoaderData} from 'react-router-dom'
import {css} from '@emotion/react'
import {GAP_LARGE} from '@/styles/config.mjs'
import Activities from './Activities.jsx'
import Header from './Header.jsx'

const cssMain = css({
})

export default function Detail(props) {
    const {user} = useLoaderData()

    return (
        <main
            css={cssMain}
            {...props}
        >
            <Header user={user} />
            <Activities />
            <Outlet />
        </main>
    )
}
