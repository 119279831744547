import {css} from '@emotion/react'
import useNextPage from '@/hooks/useNextPage.mjs'
import {cssLink, cssLinkPrimary} from '@/styles/Link.mjs'

import {
    BORDER,
    FONT_SIZE_SECONDARY,
    GAP_MEDIUM,
    GAP_SMALL,
} from '@/styles/config.mjs'

import Comment from './Comment.jsx'

const cssComments = css({
    padding: `${GAP_SMALL}px ${GAP_MEDIUM}px`,
    backgroundColor: '#fafafa',
})

const cssMore = css({
    fontSize: FONT_SIZE_SECONDARY,
    lineHeight: 1.4,
    padding: `${GAP_SMALL}px 0`,
    borderTop: BORDER,
})

export default function Comments({comments, ...props}) {
    const [commentsSize, moreComments] = useNextPage(5, 5)

    if (
        ! comments ||
        0 === comments.length
    ) {
        return null
    }

    const items = comments
        .slice(0, commentsSize)
        .map((data) => <Comment key={data.id} data={data} />)

    const buttonMore = items.length < comments.length ?
        <div css={cssMore}>
            <a
                css={[cssLink, cssLinkPrimary]}
                onClick={moreComments}
            >
                显示更多评论
            </a>
        </div>
        :
        null

    return (
        <div
            css={cssComments}
            {...props}
        >
            {items}
            {buttonMore}
        </div>
    )
}
