import {css} from '@emotion/react'
import {FONT_SIZE_SECONDARY, GAP_MEDIUM} from '@/styles/config.mjs'
import {ReactComponent as Eye} from '@/assets/eye.svg'
import {ReactComponent as Heart} from '@/assets/heart.svg'
import {ReactComponent as Message} from '@/assets/message.svg'
import {ReactComponent as User} from '@/assets/user.svg'

const cssStats = css({
    display: 'flex',
    alignItems: 'center',
    fontSize: FONT_SIZE_SECONDARY,
    gap: GAP_MEDIUM,
})

const cssStat = css({
    display: 'flex',
    alignItems: 'center',
    gap: 4,

    'svg': {
        fill: '#d2dce7',
        height: '1em',
    },
})

export default function Stats({
    accepted,
    commentCount,
    favoriteCount,
    followCount,
    viewCount,
    ...props
}) {
    return (
        <div
            css={cssStats}
            {...props}
        >
            {/*<span css={cssStat}><Message /> {commentCount}人回答</span>*/}
            <span css={cssStat}><Heart /> {favoriteCount} 人收藏</span>
            <span css={cssStat}><User /> {followCount} 人关注</span>
            <span css={cssStat}><Eye /> {viewCount} 人浏览</span>
        </div>
    )
}
