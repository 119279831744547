import {useSearchParams} from 'react-router-dom'

import useSwr from 'swr'
import {css} from '@emotion/react'
import {Pagination} from 'antd'
import useApiTopic from '@/hooks/useApiTopic.mjs'
import {DIVIDER, GAP_MEDIUM} from '@/styles/config.mjs'
import Question from './Question.jsx'

const cssList = css({
    '&>*': {
        borderBottom: DIVIDER,

        '&:first-child': {
            borderTop: DIVIDER,
        },
    }
})

const cssPagination = css({
    marginTop: GAP_MEDIUM,
    marginBottom: GAP_MEDIUM,
})

export default function Questions({path, ...props}) {
    const {listTopics} = useApiTopic()
    const [searchParams, setSearchParams] = useSearchParams()
    const keyword = searchParams.get('keyword')
    const pageNumber = searchParams.get('pageNumber')
    const pageSize = searchParams.get('pageSize')

    const {data} = useSwr(
        keyword ? `search/questions?keyword=${keyword}&pageNumber=${pageNumber}&pageSize=${pageSize}` : null,
        () => listTopics({keyword, pageNumber, pageSize})
    )

    const items = (data?.data ?? []).map(
        (data) => (
            <Question
                key={data.id}
                data={data}
            />
        )
    )

    const handleChangePagination = (pageNumber, pageSize) => {
        const sp = new URLSearchParams(searchParams)
        sp.set('pageNumber', pageNumber)
        sp.set('pageSize', pageSize)
        setSearchParams(sp)
    }

    return (
        <>
            <div
                css={cssList}
                {...props}
            >
                {items}
            </div>

            <Pagination
                css={cssPagination}
                current={Number(pageNumber)}
                hideOnSinglePage
                pageSize={Number(pageSize)}
                showSizeChanger
                total={data?.totalRecord}
                onChange={handleChangePagination}
            />
        </>
    )
}
