import {Button} from 'antd'
import PostPermission from '../PostPermission.jsx'
import {EditOutlined} from "@ant-design/icons";

export default function ButtonEdit({
    isEditing,
    loading,
    onCancel,
    onEdit,
    onFinish,
    ...props
}) {
    if (isEditing) {
        return (
            <>
                <Button
                    loading={loading}
                    type="primary"
                    onClick={onFinish}
                    {...props}
                >
                    提交
                </Button>

                <Button
                    loading={loading}
                    onClick={onCancel}
                    {...props}
                >
                    取消
                </Button>
            </>
        )
    }
    else {
        return (
            <PostPermission every={['edit']}>
                <Button
                    icon={<EditOutlined />}
                    ghost
                    type="primary"
                    onClick={onEdit}
                    {...props}
                >
                    编辑
                </Button>
            </PostPermission>
        )
    }
}
