import {Link} from 'react-router-dom'
import {cssLink, cssLinkPrimary} from '@/styles/Link.mjs'

export default function SeeAll(props) {
    return (
        <Link
            css={[cssLink, cssLinkPrimary]}
            target="_blank"
            to="/communityNotices/list"
            {...props}
        >
            全部
        </Link>
    )
}
