import {css} from '@emotion/react'
import useBizTable from '@/hooks/useBizTable.mjs'

import {
    BORDER_RADIUS_SMALL,
    FONT_SIZE_TRIVIAL,
} from '@/styles/config.mjs'

const cssType = css({
    fontSize: FONT_SIZE_TRIVIAL,
    padding: '4px 6px',
    borderRadius: BORDER_RADIUS_SMALL,

    '&[data-type="1"]': {
        color: '#ff604c',
        backgroundColor: '#ffedea',
    },

    '&[data-type="2"]': {
        color: '#0885fc',
        backgroundColor: '#e4ecfd',
    },

    '&[data-type="3"]': {
        color: '#08b454',
        backgroundColor: '#e2ffe6',
    },

    '&[data-type="4"]': {
        color: '#fc7f08',
        backgroundColor: '#fff1e3',
    },
})

export default function Type({issueTypeId, ...props}) {
    const issueTypes = useBizTable('issueTypeDtos')
    const type = issueTypes.find(({id}) => id === issueTypeId)

    if (! type) {
        return null
    }

    return (
        <div
            css={cssType}
            data-type={issueTypeId}
            {...props}
        >
            {type.name}
        </div>
    )
}
