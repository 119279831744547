import {Outlet, useSearchParams} from 'react-router-dom'
import {css} from '@emotion/react'
import {Input} from 'antd'
import cssBlock from '@/styles/Block.mjs'

const cssSearch = css({
})

export default function Search(props) {
    const [searchParams, setSearchParams] = useSearchParams()
    const keyword = searchParams.get('keyword')

    const handleSearch = (value) => {
        const keyword = value.trim()
        const sp = new URLSearchParams(searchParams)

        if (keyword) {
            sp.set('keyword', keyword)
        }
        else {
            sp.delete('keyword')
        }

        setSearchParams(sp)
    }

    return (
        <div
            css={[cssBlock, cssSearch]}
            {...props}
        >
            <Input.Search
                defaultValue={keyword}
                enterButton
                onSearch={handleSearch}
            />

            <Outlet />
        </div>
    )
}
