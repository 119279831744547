import {useState} from 'react';
import {Button, Form, Input, Space, Typography} from 'antd';
import {LockOutlined, UserOutlined} from '@ant-design/icons';
import {useAuth} from '@/hooks/auth.jsx'
import {onLogin} from '@/scripts/route.mjs'
import favicon from '/favicon.png'
import ImgBackground from './login_background.jpg'
import useDocTitle from '@/hooks/useDocTitle.mjs'
import {useNavigate} from 'react-router-dom'

const {Text} = Typography;

const NameInput = () => {
    const rules = [
        {
            required: true,
            message: '请输入用户名',
        },
    ];

    return (
        <Form.Item
            name="username"
            // label='用户名'
            rules={rules}
        >
            <Input
                placeholder="用户名"
                prefix={<UserOutlined className='login-prefix'/>}
                size="large"
            />
        </Form.Item>
    );
}

const PwdInput = () => {
    const rules = [
        {
            required: true,
            message: '请输入密码',
        },
    ];

    return (
        <Form.Item
            name="password"
            // label='密码'
            rules={rules}
        >
            <Input.Password
                placeholder="密码"
                prefix={<LockOutlined className='login-prefix'/>}
                size="large"
            />
        </Form.Item>
    );
}

const SubmitButton = (props) => {
    return (
        <Button
            htmlType="submit"
            type="primary"
            size="large"
            block
            style={{
                fontWeight: 'bold',
                borderColor: '#fe4066',
                background: '#fe4066',
                marginBottom: 12,
            }}
            {...props}
        >
            登录
        </Button>
    );
}

const LoginPage = () => {
    useDocTitle('登录')
    const {login} = useAuth()
    const navigate = useNavigate();

    // 数据模型
    const [type, setType] = useState('user');

    // 加载
    const [loading, setLoading] = useState(false);

    const handleFinish = async (values) => {
        try {
            setLoading(true);
            await login(values)
            onLogin()
        } catch (err) {
            console.error(err);
        } finally {
            setLoading(false);
        }
    }

    const css = {
        display: 'flex',
        flexDirection: 'column',
        height: '100vh',
        overflow: 'hidden',
        backgroundImage: `url(${ImgBackground})`,
        backgroundSize: 'cover',
        lineHeight: 1.5715,
        '& .container': {
            display: 'flex',
            flexDirection: 'column',
            flexGrow: 1,
            backgroundColor: 'rgba(53, 48, 48, 0.7)',
            '& .header': {
                fontSize: 40,
                fontWeight: 'bold',
                color: '#fff',
                padding: '32px 64px',
                display: 'flex',
                alignItems: 'center',
                '& img': {
                    height: 40,
                    marginRight: 10,
                }
            },
            '& .main': {
                flexGrow: 1,
                display: 'flex',
                alignItems: 'center',
                justifyContent: ' center',
                '& .left-text': {
                    display: 'flex',
                    flexDirection: 'column',
                    color: '#fff',
                    '& h2': {
                        fontSize: 32,
                        fontWeight: 'bold',
                        color: '#fff',
                        margin: 0,
                    },
                    '& p': {
                        fontSize: 16,
                        fontWeight: 'bold',
                        marginTop: 12
                    }
                },
                '& .right-panel': {
                    marginLeft: 64,
                    background: '#fff',
                    borderRadius: 32,
                    boxShadow: '0 16px 32px 0 rgb(0 0 0 / 8%)',
                    display: 'flex',
                    flexDirection: 'column',
                    padding: 64,
                    width: 488,
                    '& h1': {
                        fontSize: 30,
                        fontWeight: 'bold',
                        marginBottom: 12
                    },
                    '& form .ant-form-item-explain-error': {
                        fontWeight: 'bold',
                    }
                }
            },
            '& .footer': {
                color: 'rgba(255, 255, 255, 0.7)',
                padding: '32px 64px',
                '& a': {
                    color: 'rgba(255, 255, 255, 0.7)',
                },
                '& a:hover': {
                    color: 'rgba(255, 255, 255)',
                }
            },
        },
    }

    return (
        <div css={css}>
            <div className="container">
                <div className='header'>
                    <img
                        alt="logo"
                        src={favicon}
                    />
                    软件地图社区
                </div>
                <div className="main">
                    <div className="left-text">
                        <h2>你负责提问，我们负责解答！</h2>
                        <p>与百万人一起探索软件设计的价值，发现软件建模的乐趣</p>
                    </div>
                    <div className="right-panel">
                        <h1>欢迎使用软件地图社区</h1>
                        <Form onFinish={handleFinish} layout='vertical'>
                            <NameInput/>
                            <PwdInput/>
                            <SubmitButton loading={loading}/>
                        </Form>
                        <div>
                            <Text type='secondary'>没有账号?</Text> <a href="" style={{color: '#FE4066', fontWeight: 'bold'}} onClick={() => navigate('/register')}>立即注册</a>
                        </div>
                    </div>
                </div>
                <div className="footer">
                    <div>©2016-2023 Hopedove Software Co.,Ltd. All rights reserved.</div>
                    <Space>
                        <a href='https://beian.miit.gov.cn/#/Integrated/index' target='_blank'>苏ICP备15043145号</a>
                        <a href='#' target='_blank'>隐私政策</a>
                        <a href='#' target='_blank'>使用协议</a>
                    </Space>
                </div>
            </div>

        </div>
    );
}

export default LoginPage;
