import {redirect} from 'react-router-dom'
import {makePath} from '@/scripts/route.mjs'
import Questions from './Questions.jsx'

const path = 'questions'

export default (base) => {
    const fullPath = `${base}/${path}`

    return {
        element: <Questions />,

        loader: async ({request}) => {
            const {searchParams} = new URL(request.url)
            const pageNumber = searchParams.get('pageNumber')
            const pageSize = searchParams.get('pageSize')

            if (pageNumber && pageSize) {
                return null
            }
            else {
                const to = makePath(
                    fullPath,
                    {},

                    {
                        pageNumber: 1,
                        pageSize: 10,
                        ...Object.fromEntries(searchParams.entries()),
                    }
                )

                return redirect(to)
            }
        },

        path,
    }
}

export const indexPath = path
