import {createContext, useContext} from 'react'

const PermissionContext = createContext({})

export const PermissionProvider = ({scope, value, ...props}) => {
    const permissions = useContext(PermissionContext)
    const contextValue = {...permissions, [scope]: value}

    return (
        <PermissionContext.Provider
            value={contextValue}
            {...props}
        />
    )
}

export const usePermission = (scope) => {
    const permissions = useContext(PermissionContext)
    const permission = permissions[scope] ?? {}
    return (perm) => permission[perm]
}

const Permission = ({
    children,
    every,
    failElement = null,
    okElement = children,
    scope,
    some,
    unknownElement = failElement,
}) => {
    const getPermission = usePermission(scope)
    let unknown = false

    // 对于 every 的每一项权限，只要有一项失败，就判定为失败；
    // 如果没有失败，但有未知，就判定为未知；全部成功才视为成功
    if (every) {
        for (const permName of every) {
            const perm = getPermission(permName)

            if (false === perm) {
                return failElement
            }

            if (undefined === perm) {
                unknown = true
            }
        }

        if (unknown) {
            return unknownElement
        }
    }

    // 对于 some 的每一项权限，只要有一项成功，就判定为成功；
    // 如果没有成功，但有未知，就判定为未知；全部失败才视为失败
    if (some) {
        for (const permName of some) {
            const perm = getPermission(permName)

            if (true === perm) {
                return okElement
            }

            if (undefined === perm) {
                unknown = true
            }
        }

        if (unknown) {
            return unknownElement
        }
        else {
            return failElement
        }
    }

    // 没有指定 some 且 every 判定成功，整体视为成功
    return okElement
}

export default Permission
