import {ConfigProvider} from 'antd'
import locale from 'antd/locale/zh_CN'
import dayjs from 'dayjs'
import 'dayjs/locale/zh-cn'

dayjs.locale('zh-cn')

const config = {
    locale,
    theme: {
        token: {
            colorPrimary: '#0099CC',
        },
    }
}

export default function AntdConfig(props) {
    return (
        <ConfigProvider
            {...config}
            {...props}
        />
    )
}
