import {css} from '@emotion/react'
import {FONT_SIZE_SECONDARY, TEXT_COLOR_TRIVIAL} from '@/styles/config.mjs'

const cssTime = css({
    color: TEXT_COLOR_TRIVIAL,
    fontSize: FONT_SIZE_SECONDARY,
})

export default function ModifyTime({createTime, modifyTime, ...props}) {
    const children = createTime === modifyTime ?
        `发布于 ${createTime}` :
        `修改于 ${modifyTime}`

    return (
        <div
            css={cssTime}
            {...props}
        >
            {children}
        </div>
    )
}
