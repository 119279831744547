import {Outlet} from "react-router-dom";
import cssBlock from "@/styles/Block.mjs";
import {css} from '@emotion/react'
import {GAP_MEDIUM} from "@/styles/config.mjs";

const cssCommunityNotices = css({
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: '#f4f5f5',
    paddingTop: GAP_MEDIUM,
    paddingBottom: GAP_MEDIUM,

    '@media (max-width: 899px)': {
        display: 'block',
    },
})

export default function CommunityNotices(props) {
    return (
        <>
            <div></div>
            <div
                css={[cssBlock, cssCommunityNotices]}
                {...props}
            >
                <Outlet/>
            </div>
        </>
    )
}
