import {useState} from 'react'
import {useNavigation, useSubmit} from 'react-router-dom'
import {css} from '@emotion/react'
import RichTextEditor from '@/components/RichTextEditor/RichTextEditor.jsx'
import {GAP_MEDIUM} from '@/styles/config.mjs'
import {PostPermissionProvider} from '../PostPermission.jsx'
import AcceptState from './AcceptState.jsx'
import ButtonAccept from './ButtonAccept.jsx'
import ButtonEdit from './ButtonEdit.jsx'
import Comments from './Comments.jsx'
import ModifyTime from './ModifyTime.jsx'
import WriteComment from './WriteComment.jsx'
import Author from "./Author.jsx";

import { Badge} from 'antd'

const cssPost = css({
    display: 'flex',
    flexDirection: 'column',
    // gridTemplateColumns: '56px 1fr 200px',
    // alignItems: 'start',
    gap: GAP_MEDIUM,
    padding: `${GAP_MEDIUM}px 0`,
})

const cssPostRow = css({
    display: 'flex',

})
// const cssAccept = css({
//     gridArea: '3 / 1 / 4 / 2',
//     justifySelf: 'center',
// })
//
// const cssModifyTime = css({
//     gridArea: '1 / 3 / 2 / 4',
// })

const cssContent = css({
    // gridArea: '2 / 2 / 4 / 3',
})

const cssOpearations = css({
    // gridArea: '4 / 2 / 5 / 3',
    display: 'flex',
    gap: GAP_MEDIUM,
})

const cssComments = css({
    // gridArea: '5 / 2 / 6 / 4',
})

const cssWriteComment = css({
    // gridArea: '7 / 2 / 8 / 4',
})

export default function Post({post, topic, ...props}) {
    const navigation = useNavigation()
    const submit = useSubmit()
    const [content, setContent] = useState(post.content)
    const [isEditing, setIsEditing] = useState(false)

    const handleSubmit = () => {
        const fd = new FormData()
        fd.set('id', post.id)
        fd.set('content', content)
        submit(fd, {action: `comments/${post.id}`, method: 'PUT'})
        setIsEditing(false)
    }

    return (
        <PostPermissionProvider
            post={post}
            topic={topic}
        >
            <div
                css={cssPost}
                {...props}
            >
                <div css={[cssPostRow, {justifyContent: 'space-between'}]}>
                    <Author
                        avatar = {post.authorAvatar}
                        name = {post.authorName}
                        userId = {post.authorId}
                        crtTime = {post.crtTime}
                        uptTime = {post.uptTime}
                    />

                    <AcceptState
                        acceptState={post.acceptState}
                        acceptTime={post.acceptTime}
                    />
                </div>

                <RichTextEditor
                    css={cssContent}
                    disabled={'submitting' === navigation.state}
                    readOnly={!isEditing}
                    value={content}
                    onChange={setContent}
                />

                <div css={cssOpearations}>
                    <ButtonAccept postId={post.id}/>

                    <ButtonEdit
                        isEditing={isEditing}
                        loading={'submitting' === navigation.state}
                        onFinish={handleSubmit}
                        onEdit={() => setIsEditing(true)}
                        onCancel={() => setIsEditing(false)}
                    />
                </div>

                <Comments
                    css={cssComments}
                    comments={post.issueComments}
                />

                <WriteComment
                    css={cssWriteComment}
                    issueMainId={topic.id}
                    targetUserId={post.authorId}
                    targetCommentId={post.id}
                />
            </div>
        </PostPermissionProvider>
    )
}
