import useApiUser from '@/hooks/useApiUser.mjs'
import {redirect} from 'react-router-dom'
import {makePath} from '@/scripts/route.mjs'
import QuestionList from './QuestionList.jsx'

export default (base) => {
    const {
        listQuestionsAsked,
        listQuestionsCommented,
        listQuestionsFavorited,
        listQuestionsFollowed,
    } = useApiUser()

    const childPaths = [
        ['commented-questions', listQuestionsCommented],
        ['asked-questions', listQuestionsAsked],
        ['favorited-questions', listQuestionsFavorited],
        ['followed-questions', listQuestionsFollowed],
    ]

    return childPaths.map(([path, listQuestions]) => {
        const routePath = `${path}/:pageNumber?`
        const fullPath = `${base}/${routePath}`

        return {
            element: <QuestionList path={fullPath} />,

            loader: async ({params, request}) => {
                const {pageNumber} = params
                const {searchParams} = new URL(request.url)
                const pageSize = searchParams.get('pageSize')

                if (pageNumber && pageSize) {
                    const topics = await listQuestions({pageNumber, pageSize})
                    return {topics}
                }
                else {
                    const to = makePath(
                        fullPath,

                        {
                            pageNumber: 1,
                            ...params
                        },

                        {
                            pageSize: 10,
                            ...Object.fromEntries(searchParams.entries()),
                        }
                    )

                    return redirect(to)
                }
            },

            path: routePath,
        }
    })
}

export const indexPath = 'commented-questions'
