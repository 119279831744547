import {css} from '@emotion/react'
import {Typography, theme} from 'antd'
import {GAP_MEDIUM, GAP_SMALL} from '@/styles/config.mjs'
import RichTextEditor from '@/components/RichTextEditor/RichTextEditor.jsx'
import User from '@/components/User/User.jsx'
import UserLabel from "@/components/UserLabel/UserLabel.jsx";
import Stats from './Stats.jsx'
import Type from './Type.jsx'
import Action from "./Action.jsx";
import ButtonAnswer from './ButtonAnswer.jsx'
import ButtonEdit from './ButtonEdit.jsx'
import ButtonFavorite from './ButtonFavorite.jsx'
import ButtonFollow from './ButtonFollow.jsx'
import ButtonShare from "@/pages/Main/Questions/Detail/Question/ButtonShare.jsx";
import ButtonReport from "@/pages/Main/Questions/Detail/Question/ButtonReport.jsx";

const {Text} = Typography

const cssQuestion = css({
    // display: 'grid',
    display: 'flex',
    flexDirection: 'column',
    // gridTemplateColumns: '56px 1fr 200px',
    gap: GAP_MEDIUM,
    backgroundColor: '#fff',
    padding: GAP_MEDIUM,
})

const cssQuestionSegment = css({
    display: 'flex',
    alignItems: 'center',
    gap: GAP_MEDIUM,
})

const cssType = css({
    // gridArea: '1 / 1 / 2 / 2',
})

// const cssTitle = css({
//     // gridArea: '1 / 2 / 2 / 4',
//     fontSize: 20,
// })
const cssTitle = css({
    fontSize: 16,
    lineHeight: 1.6,
    fontWeight: 700,
})

const cssStats = css({
    // gridArea: '2 / 2 / 3 / 4',
    flexGrow: 1,
})

const cssAction = css({})

const cssContent = css({
    // gridArea: '3 / 2 / 4 / 3',
})

const cssOpearations = css({
    // gridArea: '5 / 2 / 6 / 3',
    display: 'flex',
    justifyContent: 'flex-start',
    gap: GAP_MEDIUM,
    '& a': {
        color: '#6a737c',
    },
    '& a:hover': {
        color: '#6a737c',
    },
})

const cssUser = css({
    // gridArea: '4 / 3 / 5 / 4',
})


export default function Question({
                                     data: {
                                         authorAvatar,
                                         authorId,
                                         authorName,
                                         content,
                                         crtTime,
                                         favoriteCount,
                                         favoriteFlag,
                                         followCount,
                                         followFlag,
                                         id,
                                         issueTypeId,
                                         title,
                                         uptTime,
                                         viewCount,
                                     },

                                     ...props
                                 }) {
    const {useToken} = theme
    const {token} = useToken()

    return (
        <div
            css={[cssQuestion, {borderTop: `1.5px solid ${token.colorPrimary}`,}]}
            {...props}
        >
            <div css={cssQuestionSegment}>
                <Type
                    css={cssType}
                    issueTypeId={issueTypeId}
                />
                <div css={cssTitle}>
                    {title}
                </div>
            </div>

            <div css={cssQuestionSegment}>
                <User
                    css={cssUser}
                    avatar={authorAvatar}
                    name={authorName}
                    userId={authorId}
                />
                <UserLabel id={authorId}/>
                <Stats
                    css={cssStats}
                    crtTime={crtTime}
                    favoriteCount={favoriteCount}
                    followCount={followCount}
                    viewCount={viewCount}
                />
                <Action datetime={crtTime}/>
            </div>


            <RichTextEditor
                css={cssContent}
                readOnly
                value={content}
            />

            <div css={cssOpearations}>
                <ButtonAnswer/>
                <ButtonEdit questionId={id}/>
                <ButtonFollow
                    isFollowed={followFlag}
                    questionId={id}
                />

                <div style={{flexGrow: 1}}></div>

                <div>
                    <ButtonFavorite
                        isFavorited={favoriteFlag}
                        questionId={id}
                    />

                    <ButtonShare questionId={id}/>

                    <ButtonReport questionId={id}/>
                </div>
            </div>


            {/*<div css={cssModifyTime}>修改于 {uptTime}</div>*/}
        </div>
    )
}
