import {Link} from 'react-router-dom'
import {Button} from 'antd'
import {cssButton, cssButtonPrimary} from '@/styles/Button.mjs'

export default function ButtonAsk(props) {
    return (
        <Link
            // css={[cssButton, cssButtonPrimary]}
            to="/questions/ask"
            {...props}
        >
            <Button type="primary" block>提问</Button>
        </Link>
    )
}
