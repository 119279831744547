import {useNavigate, useNavigation, useSubmit} from 'react-router-dom'
import {Button} from 'antd'
import PostPermission from '../PostPermission.jsx'
import {PlusOutlined, CloseOutlined} from "@ant-design/icons";

export default function ButtonFollow({isFollowed, questionId, ...props}) {
    const navigate = useNavigate()
    const navigation = useNavigation()
    const submit = useSubmit()

    const handleClick = () => {
        const action = isFollowed ? 'unfollow' : 'follow'
        const fd = new FormData()
        fd.set('issueMainId', questionId)
        submit(fd, {action, method: 'PUT'})
    }

    const okElement = (
        <Button
            ghost
            type="primary"
            icon={isFollowed? <CloseOutlined /> : <PlusOutlined />}
            loading={'submitting' === navigation.state}
            onClick={handleClick}
            {...props}
        >
            {isFollowed ? '取消关注' : '关注'}
        </Button>
    )

    const handleLogin = () => {
        navigate('/login?r=-1')
    }

    const unknownElement = (
        <Button
            ghost
            type="primary"
            icon={<PlusOutlined />}
            onClick={handleLogin}
            {...props}
        >
            关注
        </Button>
    )

    return (
        <PostPermission
            every={['follow']}
            okElement={okElement}
            unknownElement={unknownElement}
        />
    )
}
