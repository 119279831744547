import {css} from '@emotion/react'
import {GAP_LARGE} from '@/styles/config.mjs'

export const cssQuesion = css({
    display: 'flex',
    flexDirection: 'column',
    gap: GAP_LARGE,
    backgroundColor: '#fff',
    padding: GAP_LARGE,
})
