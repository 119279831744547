import {message, Modal} from 'antd';

const runAsync = async (
    fn,

    {
        error: {
            content: errorContent = (err) => err.message,
            duration: errorDuration = 5,
            show: showError = true,
        } = {},

        loading: {
            content: loadingContent = '请稍候',
            show: showLoading = false,
        } = {},

        success: {
            content: successContent = () => '成功',
            duration: succussDuration = 2,
            show: showSuccess = false,
        } = {},
    } = {},
) => {
    let hideLoading;

    if (showLoading) {
        hideLoading = message.loading(loadingContent, 0);
    }

    try {
        const result = await fn();

        if (showLoading) {
            hideLoading();
        }

        if (showSuccess) {
            message.success(
                successContent(result),
                succussDuration
            );
        }

        return result;
    }
    catch (err) {
        if (showLoading) {
            hideLoading();
        }

        if (
            showError &&
            '00000011' !== err.code &&
            '00000012' !== err.code
        ) {
            //message.error(errorContent(err), errorDuration);
            Modal.error({
                content: errorContent(err),
            })
        }

        throw err;
    }
};

export default runAsync;
