import {css} from '@emotion/react'

import {
    BORDER,
    BORDER_RADIUS_SMALL,
    FONT_SIZE_PRIMARY,
    FONT_SIZE_TRIVIAL,
    GAP_MEDIUM,
    GAP_SMALL,
} from '@/styles/config.mjs'

const cssCard = css({
    display: 'flex',
    flexDirection: 'column',
    gap: GAP_SMALL,
    backgroundColor: '#fff',
    // padding: GAP_MEDIUM,
    // border: BORDER,
    borderRadius: BORDER_RADIUS_SMALL,
})

const cssTitle = css({
    fontSize: FONT_SIZE_PRIMARY,
    fontWeight: 600,
})

const cssSubTitle = css({
    fontSize: FONT_SIZE_TRIVIAL,
})

export default function Card({children, subTitle, title, ...props}) {
    return (
        <div
            css={cssCard}
            {...props}
        >
            <div css={cssTitle}>{title}</div>
            <div css={cssSubTitle}>{subTitle}</div>
            {children}
        </div>
    )
}
