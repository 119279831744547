import Detail from './Detail.jsx'
import {redirect} from "react-router-dom";

const path = 'detail'

export default (base) => {
    const fullPath = `${base}/${path}`

    const children = []

    return {
        children,
        element: <Detail/>,
        loader: async ({params, request}) => {
            const {searchParams} = new URL(request.url)
            const id = searchParams.get('id')
            console.log(id)
            if (id) {
                return null;
            } else {
                return redirect('/communityNotices/list')
            }
        },
        path,
    }
}

