import md5 from 'crypto-js/md5'
import WordArray from 'crypto-js/lib-typedarrays'
let fingerprint = ''

const createFingerprint = async () => {
    const canvas = new OffscreenCanvas(220, 30)
    const ctx = canvas.getContext('2d')
    const s = 'BrowserLeaks,com <canvas> 1.0'
    ctx.textBaseline = 'top'
    ctx.font = '14px Arial'
    ctx.textBaseline = 'alphabetic'
    ctx.fillStyle = '#f60'
    ctx.fillRect(125,1,62,20)
    ctx.fillStyle = '#069'
    ctx.fillText(s, 2, 15)
    ctx.fillStyle = 'rgba(102, 204, 0, 0.7)'
    ctx.fillText(s, 4, 17)
    const blob = await canvas.convertToBlob()
    const ab = await blob.arrayBuffer()
    const wa = WordArray.create(ab)
    return md5(wa).toString()
}

export default async () => {
    if (! fingerprint) {
        fingerprint = await createFingerprint()
    }

    return fingerprint
}
