import {useState} from 'react'
import {useNavigate, useSubmit} from 'react-router-dom'
import {Button, theme} from 'antd'
import useApiTopic from '@/hooks/useApiTopic.mjs'
import runAsync from '@/scripts/runAsync.mjs'
import {cssQuesion} from './cssQuestion.mjs'
import Detail from './Detail.jsx'
import Header from './Header.jsx'
import Title from './Title.jsx'
import Type from './Type.jsx'
import useDocTitle from "@/hooks/useDocTitle.mjs";
import {SmileOutlined} from '@ant-design/icons'
export default function Ask(props) {
    const navigate = useNavigate()
    const submit = useSubmit()
    const {createTopic} = useApiTopic()
    const [formData, setFormData] = useState({})
    const {useToken} = theme
    const {token} = useToken()
    useDocTitle('提问')
    const handleChangeDetail = (content) => {
        setFormData((formData) => ({...formData, content}))
    }

    const handleChangeTitle = (title) => {
        setFormData((formData) => ({...formData, title}))
    }

    const handleChangeType = (issueTypeId) => {
        setFormData((formData) => ({...formData, issueTypeId}))
    }

    const handleClickSubmit = async () => {
        const {id} = await runAsync(() => createTopic(formData))
        history.replaceState(history.state, null, `/questions/${id}`)
    }

    return (
        <div
            css={[cssQuesion, {borderTop: `1.5px solid ${token.colorPrimary}`,}]}
            {...props}
        >
            <Header />

            <Type
                value={formData.issueTypeId}
                onChange={handleChangeType}
            />

            <Title
                value={formData.title}
                onChange={handleChangeTitle}
            />

            <Detail
                value={formData.content}
                onChange={handleChangeDetail}
            />

            <Button
                style={{alignSelf: 'flex-end'}}
                icon={<SmileOutlined />}
                disabled={
                    ! (
                        formData.content &&
                        formData.issueTypeId &&
                        formData.title
                    )
                }

                loading={'submitting' === navigate.state}
                size="large"
                type="primary"
                onClick={handleClickSubmit}
            >
                立即发布
            </Button>
        </div>
    )
}
