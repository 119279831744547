import {css} from '@emotion/react'
import {FONT_SIZE_PRIMARY, TEXT_COLOR_PRIMARY} from './config.mjs'

export default css({
    '*': {
        boxSizing: 'border-box',
        lineHeight: 'inherit',
    },

    ':root': {
        scrollbarGutter: 'stable',
    },

    body: {
        margin: 0,
    },

    '#root': {
        display: 'grid',
        height: '100%',
        color: TEXT_COLOR_PRIMARY,
        fontSize: FONT_SIZE_PRIMARY,
        lineHeight: 1,
    },
})
