import {css} from '@emotion/react'
import {GAP_LARGE, GAP_MEDIUM} from '@/styles/config.mjs'
import FormAnswer from './FormAnswer.jsx'
import LoginTip from './LoginTip.jsx'
import PostPermission from '../PostPermission.jsx'
import {theme} from "antd";

const cssWriteAnswer = css({
    display: 'flex',
    flexDirection: 'column',
    gap: GAP_LARGE,
    backgroundColor: '#fff',
    padding: GAP_MEDIUM,
})

export default function WriteAnswer({issueMainId, ...props}) {
    const {useToken} = theme
    const {token} = useToken()
    const cssBorderTop = css({
        borderTop: `1.5px solid ${token.colorPrimary}`,
    })
    return (
        <PostPermission
            every={['reply']}

            okElement={
                <div
                    id="write-answer"
                    css={[cssWriteAnswer, cssBorderTop]}
                    {...props}
                >
                    <h2>你的回答</h2>
                    <FormAnswer issueMainId={issueMainId}/>
                </div>
            }

            unknownElement={
                <div
                    id="write-answer"
                    css={[cssWriteAnswer, cssBorderTop]}
                    {...props}
                >
                    <h2>你的回答</h2>
                    <LoginTip/>
                </div>
            }
        />
    )
}
