import {css} from '@emotion/react'
import {GAP_LARGE} from '@/styles/config.mjs'
import Mine from '@/components/Mine/Mine.jsx'
import CommunityNoticeBoard from "@/components/CommunityNoticeBoard/CommunityNoticeBoard.jsx";

const cssAside = css({
    display: 'flex',
    flexDirection: 'column',
    gap: GAP_LARGE,
})

export default function Aside(props) {
    return (
        <aside
            css={cssAside}
            {...props}
        >
            <Mine />
            <CommunityNoticeBoard />
        </aside>
    )
}
