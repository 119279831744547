import {useNavigate, useNavigation, useSubmit} from 'react-router-dom'
import {Button} from 'antd'
import PostPermission from '../PostPermission.jsx'
import {ShareAltOutlined} from '@ant-design/icons'

export default function ButtonShare({...props}) {
    const navigate = useNavigate()
    const navigation = useNavigation()
    const submit = useSubmit()

    return (
        <Button
            icon={<ShareAltOutlined/>}
            loading={'submitting' === navigation.state}
            type="text"
            {...props}
        >
            分享
        </Button>
    )
}
