import {css} from '@emotion/react'
import {GAP_MEDIUM, GAP_SMALL} from '@/styles/config.mjs'

import Action from './Action.jsx'
import Stats from './Stats.jsx'
import Title from './Title.jsx'
import Type from './Type.jsx'
import Author from './Author.jsx'
import AnswerStats from "./AnswerStats.jsx";
import {Space} from "antd";

const cssQuestion = css({
    display: 'flex',
    padding: GAP_MEDIUM,
    gap: GAP_MEDIUM,
    alignItems: 'center',
})

const cssQuestion2 = css({
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    gap: GAP_MEDIUM,
})

const cssQuestionSegment = css({
    display: 'flex',
    alignItems: 'center',
    flexShrink: 0,
})

const cssType = css({
    // gridArea: '1 / 1 / 2 / 2',
})

const cssTitle = css({
    // gridArea: '1 / 2 / 2 / 3',
})

const cssStats = css({
    // gridArea: '3 / 2 / 4 / 3',
    flexGrow: 1,
})

const cssUser = css({
    // gridArea: '2 / 3 / 3 / 4',
})

const cssAction = css({
    // gridArea: '3 / 3 / 4 / 4',
})

export default function Question({
                                     data: {
                                         commentCount,
                                         crtTime,
                                         favoriteCount,
                                         followCount,
                                         id,
                                         issueTypeId,
                                         state,
                                         title,
                                         uptName,
                                         uptTime,
                                         viewCount,
                                         authorId,
                                         authorName,
                                         authorAvatar,
                                     },

                                     ...props
                                 }) {
    return (
        <div
            css={cssQuestion}
            {...props}
        >
            <AnswerStats commentCount={commentCount}/>
            <div css={cssQuestion2}>
                <Space>
                    <Type
                        css={cssType}
                        issueTypeId={issueTypeId}
                    />
                    <Title
                        css={cssTitle}
                        questionId={id}
                    >
                        {title}
                    </Title>

                </Space>

                <div css={[cssQuestionSegment, {gap: GAP_SMALL}]}>
                    <Author
                        avatar={authorAvatar}
                        name={authorName}
                        userId={authorId}
                    />
                    <Stats
                        css={cssStats}
                        accepted={1 === state}
                        commentCount={commentCount}
                        favoriteCount={favoriteCount}
                        followCount={followCount}
                        viewCount={viewCount}
                    />
                    <Action datetime={crtTime}/>
                </div>
            </div>
        </div>
    )
}
