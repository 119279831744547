import {css} from '@emotion/react'
import {Typography} from "antd";

const {Text} = Typography;

const cssStats = css({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexShrink: 0,
    backgroundColor: '#f0f0f0',
    height: '3em',
    width: '3em',
    '&:hover': {
        backgroundColor: 'rgba(240,240,240,0.6)',
    }
})

const cssStat = css({})

export default function AnswerStats({
                                        commentCount,
                                        ...props
                                    }) {
    return (
        <div
            css={cssStats}
            {...props}
        >
            <Text css={cssStat}>{commentCount}</Text>
        </div>
    )
}
