import {Link} from 'react-router-dom'
import {css} from '@emotion/react'
import {cssLink, cssLinkPrimary} from '@/styles/Link.mjs'

const cssTitle = css({
    fontSize: 16,
    lineHeight: 1.6,
    fontWeight: 700,
})

export default function Title({questionId, ...props}) {
    return (
        <Link
            css={[cssLink, cssTitle]}
            target="_blank"
            to={`/questions/${questionId}`}
            {...props}
        />
    )
}
