import makeKeywordsMatcher from '@/scripts/makeKeywordsMatcher.mjs'

const selectedCountMap = new Map()
const lastSelectedMap = new Map()

const defaultOptions = []
const defaultLabel = (a) => a
const nop = () => {}

const useSelectable = ({
    hottestFirst = '',
    label = defaultLabel,
    options = defaultOptions,
    searchText = label,
    setInputValue,
    title = label,
    writeBack = label,
    onChange = nop,
}) => {
    const antdOptions = (() => {
        const antdOptions = options.map(
            ([value, a = value]) => ({
                label: label(a),
                searchText: searchText(a),
                title: title(a),
                writeBack: writeBack(a),
                value,
            })
        )

        if (hottestFirst) {
            const selectedCount = selectedCountMap.get(hottestFirst)
            const lastSelected = lastSelectedMap.get(hottestFirst)

            antdOptions.sort(({value: a}, {value: b}) => {
                if (lastSelected === a) {
                    return -1
                }

                if (lastSelected === b) {
                    return 1
                }

                if (selectedCount) {
                    const selectedCountA = selectedCount.get(a) ?? 0
                    const selectedCountB = selectedCount.get(b) ?? 0
                    return selectedCountB - selectedCountA
                }

                return 0
            })
        }

        return antdOptions
    })()

    let keyword

    const filterOption = (input, option) => {
        keyword = input
        const match = makeKeywordsMatcher(input)

        if (match) {
            return match(option.searchText)
        }
        else {
            return true
        }
    }

    const filterSort = (a, b) => {
        const ia = a.searchText.indexOf(keyword)
        const ib = b.searchText.indexOf(keyword)
        return ia - ib
    }

    const handleChange = (value) => {
        if (hottestFirst) {
            if (! selectedCountMap.has(hottestFirst)) {
                selectedCountMap.set(hottestFirst, new Map())
            }

            const selectedCount = selectedCountMap.get(hottestFirst)
            const count = selectedCount.get(value) ?? 0
            selectedCount.set(value, count + 1)
            lastSelectedMap.set(hottestFirst, value)
        }

        setInputValue(value)
        onChange(value)
    }

    return {
        antdOptions,
        filterOption,
        filterSort,
        handleChange,
    }
}

export default useSelectable
