import escapeRegex from './escapeRegex.mjs'

const makeKeywordsMatcher = (keywordString) => {
    const s = keywordString?.trim()

    if (s) {
        const keywords = s.split(/ +/)

        const regexes = keywords.map(
            (keyword) => new RegExp(escapeRegex(keyword), 'i')
        )

        return (value) => {
            return regexes.every(
                (re) => re.test(value)
            )
        }
    }
    else {
        return null
    }
}

export default makeKeywordsMatcher
