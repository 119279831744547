import {css} from '@emotion/react'
import {FONT_SIZE_TRIVIAL, GAP_MEDIUM, TEXT_COLOR_SECONDARY} from '@/styles/config.mjs'
import {Typography} from "antd"
import User from '@/components/User/User.jsx'
import UserLabel from "@/components/UserLabel/UserLabel.jsx";

const {Text} = Typography

const cssAuthor = css({
    display: 'flex',
    alignItems: 'center',
    gap: GAP_MEDIUM,
})

const cssAuthorAvatar = css({
    fontSize: 30,
})

const cssAuthorIntro = css({
    display: 'flex',
    flexDirection: 'column',
    gap: GAP_MEDIUM,
})

const cssAction = css({
    display: 'flex',
    justifySelf: 'flex-end',
    color: TEXT_COLOR_SECONDARY,
    fontSize: FONT_SIZE_TRIVIAL,
})

export default function Author({avatar, name, userId, crtTime, uptTime, ...props}) {
    return (
        <div css={cssAuthor}>
            <User
                css={cssAuthorAvatar}
                avatar={avatar}
                userId={userId}
            />

            <div css={cssAuthorIntro}>
                <div style={{display: 'flex', gap: GAP_MEDIUM}}>
                    <User
                        name={name}
                        userId={userId}
                    />
                    <UserLabel id={userId}/>
                </div>
                <div
                    css={cssAction}
                >
                    发布于 {crtTime}
                </div>
            </div>
        </div>
    )
}
