import {useAuth} from '@/hooks/auth.jsx'

import {
    default as Permission,
    PermissionProvider,
    usePermission
} from '@/components/Permission/Permission.jsx'

const SCOPE = 'post'

export default function PostPermission(props) {
    return <Permission scope={SCOPE} {...props} />
}

const getPermissions = (topic, post, user) => {
    if (! user) {
        return {}
    }

    const permissions = {}
    const isQuestion = topic.id === post.id
    const isQuestionAccepted = 1 === topic.state

    if (user.id === topic.authorId) {
        permissions.accept = ! (isQuestion || isQuestionAccepted)
        permissions.favorite = false
        permissions.follow = false
    }
    else {
        permissions.accept = false
        permissions.favorite = true
        permissions.follow = true
    }

    if (user.id === post.authorId) {
        permissions.edit = true
        permissions.reply = ! isQuestion
    }
    else {
        permissions.edit = false

        permissions.reply = ! (
            isQuestion &&

            (post.issueComments ?? []).some(
                ({authorId}) => authorId === user.id
            )
        )
    }

    return permissions
}

export const PostPermissionProvider = ({post, topic, ...props}) => {
    const {user} = useAuth()
    const permissions = getPermissions(topic, post, user)

    return (
        <PermissionProvider
            scope={SCOPE}
            value={permissions}
            {...props}
        />
    )
}

export const usePostPermission = () => {
    return usePermission(SCOPE)
}
