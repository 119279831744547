import {css} from '@emotion/react'
import {ReactComponent as IconMessage} from '@/assets/message.svg'
import {GAP_MEDIUM, GAP_SMALL} from '@/styles/config.mjs'

const cssHeader = css({
    display: 'flex',
    alignItems: 'center',
    gap: GAP_SMALL,
    fontSize: 20,
    padding: `${GAP_MEDIUM}px 0`,

    'svg': {
        height: '1em',
        fill: '#3d7fff',
    },
})

export default function Header({count, ...props}) {
    return (
        <div
            css={cssHeader}
            {...props}
        >
            <IconMessage />
            {count} 个回答
        </div>
    )
}
