import {Link} from 'react-router-dom'
import {cssLink, cssLinkPrimary} from '@/styles/Link.mjs'

export default function LoginTip(props) {
    return (
        <div {...props}>
            请先

            <Link
                css={[cssLink, cssLinkPrimary]}
                to="/login?r=-1"
            >
                登录
            </Link>

            再回答问题
        </div>
    )
}
