import {useState} from 'react'
import {useLoaderData} from 'react-router-dom'
import {css} from '@emotion/react'
import useNextPage from '@/hooks/useNextPage.mjs'
import {cssLink, cssLinkPrimary} from '@/styles/Link.mjs'
import {BORDER, GAP_MEDIUM} from '@/styles/config.mjs'
import Header from './Header.jsx'
import Post from './Post.jsx'
import {theme} from "antd";

const cssAnswers = css({
    backgroundColor: '#fff',
    padding: `0 ${GAP_MEDIUM}px`,
})

const cssAnswer = css({
    borderTop: BORDER,
})

const cssMore = css({
    textAlign: 'center',
    fontSize: 16,
    padding: `${GAP_MEDIUM}px 0`,
    borderTop: BORDER,
})

export default function Answers({topic, ...props}) {
    const loaderData = useLoaderData()
    const [answersSize, moreAnswers] = useNextPage(3, 3)
    const {useToken} = theme
    const {token} = useToken()

    const [
        writeCommentId,
        setWriteCommentId,
    ] = useState(loaderData.writeCommentId)

    if (
        ! topic.issueComments ||
        0 === topic.issueComments.length
    ) {
        return null
    }

    const handleWriteCommentEnd = () => {
        setWriteCommentId(null)
        const {searchParams} = new URL(location.href)
        searchParams.delete('write-comment')

        history.replaceState(
            history.state,
            null,
            `?${searchParams}`
        )
    }

    const handleWriteCommentStart = (id) => {
        setWriteCommentId(id)
        const {searchParams} = new URL(location.href)
        searchParams.set('write-comment', id)

        history.replaceState(
            history.state,
            null,
            `?${searchParams}`
        )
    }

    const items = topic.issueComments
        .slice(0, answersSize)
        .map((answer) => (
            <Post
                key={answer.id}
                css={cssAnswer}
                post={answer}
                topic={topic}
                writeComment={answer.id === writeCommentId}
                onWriteCommentStart={handleWriteCommentStart}
                onWriteCommentEnd={handleWriteCommentEnd}
            />
        ))

    const buttonMore = items.length < topic.issueComments.length ?
        <div
            css={[cssLink, cssLinkPrimary, cssMore]}
            onClick={moreAnswers}
        >
            显示更多回答
        </div>
        :
        null

    return (
        <div
            css={[cssAnswers, {borderTop: `1.5px solid ${token.colorPrimary}`,}]}
            {...props}
        >
            <Header count={topic.commentCount} />
            {items}
            {buttonMore}
        </div>
    )
}
