import {css} from '@emotion/react'

export const cssLink = css({
    color: 'inherit',
    textDecoration: 'none',
    cursor: 'pointer',
})

export const cssLinkPrimary = css({
    color: '#0074cc',

    '&:hover': {
        color: '#0a95ff',
    },
})

export const cssLinkSecondary = css({
    color: '#3b4045',

    '&:hover': {
        color: '#525960',
    },
})
