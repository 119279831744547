import {css} from '@emotion/react'
import {GAP_MEDIUM, GAP_SMALL} from '@/styles/config.mjs'

import Action from './Action.jsx'
import Stats from './Stats.jsx'
import Title from './Title.jsx'
import Type from './Type.jsx'

const cssQuestion = css({
    display: 'grid',
    gridTemplateColumns: '60px 1fr 200px',
    placeItems: 'center start',
    gap: GAP_SMALL,
    padding: GAP_MEDIUM,
})

const cssType = css({
    gridArea: '1 / 1 / 2 / 2',
})

const cssTitle = css({
    gridArea: '1 / 2 / 2 / 3',
})

const cssStats = css({
    gridArea: '3 / 2 / 4 / 3',
})

const cssUser = css({
    gridArea: '2 / 3 / 3 / 4',
})

const cssAction = css({
    gridArea: '3 / 3 / 4 / 4',
})

export default function Question({
    data: {
        commentCount,
        crtTime,
        favoriteCount,
        followCount,
        id,
        issueTypeId,
        state,
        title,
        uptName,
        uptTime,
        viewCount,
    },

    ...props
}) {
    return (
        <div
            css={cssQuestion}
            {...props}
        >
            <Type
                css={cssType}
                issueTypeId={issueTypeId}
            />

            <Title
                css={cssTitle}
                questionId={id}
            >{title}</Title>

            <Stats
                css={cssStats}
                accepted={1 === state}
                commentCount={commentCount}
                favoriteCount={favoriteCount}
                followCount={followCount}
                viewCount={viewCount}
            />

            <div css={cssUser}>{uptName}</div>

            <Action
                css={cssAction}
                action={crtTime === uptTime ? '发布' : '修改'}
                datetime={uptTime}
            />
        </div>
    )
}
