import {css} from '@emotion/react'
import {GAP_SMALL} from '@/styles/config.mjs'
import {Typography} from "antd";
import favicon from '@/assets/logo.png'
import {useEffect, useState} from "react";
import useApiUser from "@/hooks/useApiUser.mjs";

const {Text} = Typography
const cssUserLabel = css({
    display: 'flex',
    alignItems: 'center',
    gap: GAP_SMALL,
})


const cssIcon = css({
    width: '1.3em',
    height: '1.3em',
})

const cssText = css({
    color: '#f50!important'
})

const LabelElement = ({user: {adminFlag, officialCertificationFlag}}) => {
    const text = adminFlag ? '管理员' : (officialCertificationFlag ? '官方人员' : null)
    if (text) {
        return (
            <div css={cssUserLabel}>
                <img
                    css={cssIcon}
                    src={favicon}
                    title={text}
                />
                <Text css={cssText}>{text}</Text>
            </div>
        );
    } else {
        return <></>
    }
}

export default function UserLabel({id, adminFlag, officialCertificationFlag, ...props}) {
    const [user, setUser] = useState({})
    const {oneUserCustomer} = useApiUser()

    useEffect(() => {
        (async () => {
            if (id) {
                const user_ = await oneUserCustomer({id});
                if (user_) {
                    setUser(user_);
                }
            } else {
                setUser({
                    adminFlag, officialCertificationFlag
                });
            }
        })()
    }, [])


    return <LabelElement user={user}></LabelElement>
};
