import {Select} from 'antd'
import {useSearchParams} from 'react-router-dom'
import useBizTable from '@/hooks/useBizTable.mjs'

export default function SelectQuestionType(props) {
    const [searchParams, setSearchParams] = useSearchParams()
    const issueTypes = useBizTable('issueTypeDtos')

    const options = issueTypes.map(
        ({id: value, name: label}) => ({label, value})
    )

    const handleChange = (issueTypeId) => {
        const sp = new URLSearchParams(searchParams)

        if (issueTypeId) {
            sp.set('issueTypeId', issueTypeId)
        }
        else {
            sp.delete('issueTypeId')
        }

        setSearchParams(sp)
    }

    return (
        <Select
            allowClear
            placeholder="问题类型"
            bordered={false}
            options={options}
            onChange={handleChange}
            {...props}
        />
    )
}
