import {Link, useSearchParams} from 'react-router-dom'
import {css} from '@emotion/react'
import {DIVIDER, GAP_MEDIUM} from '@/styles/config.mjs'
import {Pagination, theme, Typography} from 'antd'
import {cssLink, cssLinkSecondary} from "@/styles/Link.mjs";
import {useEffect, useState} from "react";
import runAsync from "@/scripts/runAsync.mjs";
import useHttp from "@/hooks/useHttp.mjs";
import useDocTitle from "@/hooks/useDocTitle.mjs";

const {Text} = Typography

const cssItem = css({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: GAP_MEDIUM,
    lineHeight: 1.6,
})

const Item = ({id, title, uptTime, ...props}) => {
    const {useToken} = theme
    const {token} = useToken()
    const cssHover = {
        '&:hover': {
            color: token.colorPrimary
        }
    }

    return (
        <div css={cssItem}>
            <Link
                css={[cssLink, cssLinkSecondary, cssHover]}
                target="_blank"
                to={`/communityNotices/detail?id=${id}`}
            >
                {title}
            </Link>

            <Text type='secondary'>{uptTime}</Text>
        </div>
    )
}

const cssMain = css({
    backgroundColor: '#fff',
    display: 'flex',
    flexDirection: 'column',
})

const cssHead = css({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: GAP_MEDIUM,
    borderBottom: DIVIDER,
    fontWeight: 'bold',
})

const cssHeadText = css({
    fontWeight: 'bold',
})

const cssContent = css({
    display: 'flex',
    flexDirection: 'column',
    '&>*': {
        borderBottom: DIVIDER,

        // '&:first-child': {
        //     borderTop: DIVIDER,
        // },
    }
})

const cssPagination = css({
    marginTop: GAP_MEDIUM,
    marginBottom: GAP_MEDIUM,
})

export default function List(props) {
    const {useToken} = theme
    const {token} = useToken()

    const http = useHttp()
    const [searchParams] = useSearchParams()
    const pageSize = searchParams.get('pageSize')
    const pageNumber = searchParams.get('pageNumber')
    const [pagingWrapper, setPagingWrapper] = useState({data: [], totalRecord: 0})
    useDocTitle('社区公告')

    useEffect(() => {
        (async () => {
            const pagingWrapper = await runAsync(
                async () => {
                    return await http.get(`/communityNotice/pagingCommunityNotice`, {pageNumber, pageSize})
                },
                {
                    error: {content: (err) => `查询公告失败: ${err.message}`},
                    loading: {show: false},
                    success: {show: false},
                }
            );
            setPagingWrapper(pagingWrapper)
        })()
    }, [])

    const handleChangePagination = (pageNumber, pageSize) => {
        const newPath = updatePath(path, {pageNumber}, {pageSize})
        navigate(newPath)
    }

    return (
        <main
            css={cssMain}
            {...props}
        >
            <div css={[cssHead, {borderTop: `1.5px solid ${token.colorPrimary}`,}]}>
                <Text strong css={cssHeadText}>公告栏</Text>
            </div>

            <div css={cssContent}>
                {
                    pagingWrapper.data.map(itemData => <Item {...itemData}/>)
                }
            </div>

            <Pagination
                css={cssPagination}
                current={Number(pageNumber)}
                hideOnSinglePage
                pageSize={Number(pageSize)}
                showSizeChanger
                total={pagingWrapper.totalRecord}
                onChange={handleChangePagination}
            />
        </main>
    )
}
