import {useNavigate} from 'react-router-dom'
import {Button} from 'antd'
import PostPermission from '../PostPermission.jsx'
import { CommentOutlined } from '@ant-design/icons';

export default function ButtonAnswer(props) {
    const navigate = useNavigate()

    const handleClick = () => {
        const el = document.getElementById('write-answer')
        el.scrollIntoViewIfNeeded?.()
        el.querySelector('.ck-editor__editable')?.focus()
    }

    const okElement = (
        <Button
            icon={<CommentOutlined />}
            type="primary"
            onClick={handleClick}
            {...props}
        >
            回答问题
        </Button>
    )

    const handleLogin = () => {
        navigate('/login?r=-1')
    }

    const unknownElement = (
        <Button
            icon={<CommentOutlined />}
            type="primary"
            onClick={handleLogin}
            {...props}
        >
            回答问题
        </Button>
    )

    return (
        <PostPermission
            every={['reply']}
            okElement={okElement}
            unknownElement={unknownElement}
        />
    )
}
