import List from './List.jsx'
import {makePath} from "@/scripts/route.mjs";
import {redirect} from "react-router-dom";

const path = 'list'

export default (base) => {
    const fullPath = `${base}/${path}`

    const children = []

    return {
        children,
        element: <List/>,
        loader: async ({params, request}) => {
            const {searchParams} = new URL(request.url)
            const pageSize = searchParams.get('pageSize')
            const pageNumber = searchParams.get('pageNumber')

            if (pageNumber && pageSize) {
                return null;
            } else {
                const to = makePath(
                    fullPath,

                    {
                        ...params
                    },

                    {
                        pageNumber: 1,
                        pageSize: 10,
                        ...Object.fromEntries(searchParams.entries()),
                    }
                )

                return redirect(to)
            }
        },
        path,
    }
}

