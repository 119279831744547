import {Outlet} from 'react-router-dom'
import {css} from '@emotion/react'
import {BORDER} from '@/styles/config.mjs'
import Footer from './Footer/Footer.jsx'
import Header from './Header/Header.jsx'
import useDocTitle from "@/hooks/useDocTitle.mjs";

const cssMain = css({
    display: 'grid',
    gridTemplateRows: 'auto auto 1fr auto',
    minHeight: '100vh',
})

const cssHeader = css({
    position: 'sticky',
    zIndex: 1,
    top: 0,
    borderBottom: BORDER,
})

export default function Main(props) {
    //useDocTitle('')

    return (
        <div
            css={cssMain}
            {...props}
        >
            <Header css={cssHeader} />
            <Outlet />
            <Footer />
        </div>
    )
}
