import {css} from '@emotion/react'
import {GAP_MEDIUM} from '@/styles/config.mjs'
import CommunityNotices from './CommunityNotices.jsx'
import SeeAll from './SeeAll.jsx'
import {Badge, Divider, theme} from "antd";

const cssTitle = css({
    display: 'flex',
    justifyContent: 'space-between',
    padding: GAP_MEDIUM,

})
const cssBadge = css({
    fontWeight: 500,
})
const Title = () => {
    return (
        <div css={cssTitle}>
            <Badge css={cssBadge} key='orange' color='orange' text='公告栏'/>
            <SeeAll/>
        </div>
    )
}

export default function CommunityNoticeBoard(props) {
    const {useToken} = theme
    const {token} = useToken()
    const cssBoard = css({
        display: 'flex',
        flexDirection: 'column',
        // padding: GAP_MEDIUM,
        backgroundColor: 'white',
        borderTop: `1.5px solid ${token.colorPrimary}`
    })

    return (
        <div css={cssBoard}>
            <Title/>
            <Divider style={{margin: 0}}/>
            <CommunityNotices/>
        </div>
    )
}
