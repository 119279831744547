export const BORDER = '1px solid rgba(5, 5, 5, 0.06)'
export const BORDER_RADIUS_LARGE = 8
export const BORDER_RADIUS_MEDIUM = 6
export const BORDER_RADIUS_SMALL = 4
export const COLOR_OK = '#2f6f44'
export const DIVIDER = '1px solid rgba(5, 5, 5, 0.06)'
export const FONT_SIZE_LARGE = 16
export const FONT_SIZE_PRIMARY = 14
export const FONT_SIZE_SECONDARY = 12.5
export const FONT_SIZE_TRIVIAL = 11
export const GAP_LARGE = 24
export const GAP_MEDIUM = 16
export const GAP_SMALL = 8
export const TEXT_COLOR_IMPORTANT = '#000'
export const TEXT_COLOR_PRIMARY = '#3b4045'
export const TEXT_COLOR_SECONDARY = '#6a737c'
export const TEXT_COLOR_TRIVIAL = '#838c95'
