import {Outlet} from 'react-router-dom'
import {css} from '@emotion/react'
import cssBlock from '@/styles/Block.mjs'

const cssUsers = css({
})

export default function Users(props) {
    return (
        <div
            css={[cssBlock, cssUsers]}
            {...props}
        >
            <Outlet />
        </div>
    )
}
