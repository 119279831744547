import {css} from '@emotion/react'
import {theme, Typography} from 'antd'
import {GAP_MEDIUM} from '@/styles/config.mjs'
import RichTextEditor from '@/components/RichTextEditor/RichTextEditor.jsx'
import User from '@/components/User/User.jsx'
import UserLabel from "@/components/UserLabel/UserLabel.jsx";
import Action from "./Action.jsx";
import useHttp from "@/hooks/useHttp.mjs";
import {useSearchParams} from "react-router-dom";
import {useEffect, useState} from "react";
import runAsync from "@/scripts/runAsync.mjs";
import useDocTitle from "@/hooks/useDocTitle.mjs";

const {Text} = Typography

const cssDetail = css({
    // display: 'grid',
    display: 'flex',
    flexDirection: 'column',
    // gridTemplateColumns: '56px 1fr 200px',
    gap: GAP_MEDIUM,
    backgroundColor: '#fff',
    padding: GAP_MEDIUM,
})

const cssDetailSegment = css({
    display: 'flex',
    alignItems: 'center',
    gap: GAP_MEDIUM,
})

const cssTitle = css({
    fontSize: 16,
    lineHeight: 1.6,
    fontWeight: 700,
})

export default function Detail({...props}) {
    const {useToken} = theme
    const {token} = useToken()

    const http = useHttp()
    const [searchParams] = useSearchParams()
    const id = searchParams.get('id')
    const [data, setData] = useState(null)
    useDocTitle(['公告', '社区公告'])

    useEffect(() => {
        (async () => {
            const data = await runAsync(
                async () => {
                    return await http.get(`/communityNotice/oneCommunityNotice`, {id})
                },
                {
                    error: {content: (err) => `查询公告失败: ${err.message}`},
                    loading: {show: false},
                    success: {show: false},
                }
            );

            setData(data)

        })()
    }, [])

    return (
        <>
            <div></div>
            <div css={[cssDetail, {borderTop: `1.5px solid ${token.colorPrimary}`,}]}
                 {...props}
            >
                {
                    data && (
                        <>
                            <div css={cssDetailSegment}>
                                <div css={cssTitle}>
                                    {data.title}
                                </div>
                            </div>

                            <div css={cssDetailSegment}>
                                <User
                                    avatar={data.authorAvatar}
                                    name={data.authorName}
                                    userId={data.authorId}
                                />
                                <UserLabel id={data.authorId}/>
                                <Action datetime={data.crtTime}/>
                            </div>

                            <RichTextEditor
                                readOnly
                                value={data.content}
                            />
                        </>
                    )
                }
            </div>
        </>
    )
}
