import Users from './Users.jsx'
import useRoutesDetail from './Detail/useRoutes.jsx'

const path = 'users'

export default (base) => {
    const fullPath = `${base}/${path}`

    const children = [
        useRoutesDetail(fullPath),
    ]

    return {
        children,
        element: <Users />,
        path,
    }
}
