import {
    createContext,
    useContext,
    useEffect,
    useRef,
    useState,
} from 'react'

import httpRequest from '@/scripts/http.mjs'
import runAsync from '@/scripts/runAsync.mjs'

const AuthContext = createContext()
const TokenContext = createContext()

export const useAuth = () => useContext(AuthContext)
export const useToken = () => useContext(TokenContext)

const doLogin = ({password, username}) => runAsync(
    async () => httpRequest({
        method: 'POST',
        url: `${import.meta.env.VITE_HTTP_BASE_URL}/login/token`,
        payload: {password, username},
    }),

    {
        error: {content: (err) => `登录失败: ${err.message}`},
        loading: {show: false},
    }
)

const doRefreshToken = ({token, refreshToken}) => runAsync(
    async () => httpRequest({
        headers: {__TOKEN__: token},
        method: 'POST',
        url: `${import.meta.env.VITE_HTTP_BASE_URL}/login/token/refresh`,
        payload: {token, refreshToken},
    }),

    {
        //error: {content: (err) => `刷新令牌失败: ${err.message}`},
        error: {show: false},
        loading: {show: false},
    }
)

const STO_KEY = 'auth'
const BC_TOKEN = 'token'
const BC_USER = 'user'

const initAuth = () => {
    try {
        const data = localStorage.getItem(STO_KEY)

        if (! data) {
            return null
        }

        const auth = JSON.parse(data)

        if (! auth) {
            return null
        }

        const SAFE_REFRESH_TOKEN_DELAY = 30000

        if (
            SAFE_REFRESH_TOKEN_DELAY <
            auth.token.refreshTokenExpires - Date.now()
        ) {
            return auth
        }
        else {
            return null
        }
    }
    catch (err) {
        console.error(err)
        return null
    }
}

export const AuthProvider = (props) => {
    const [auth, setAuth] = useState(initAuth)
    const refBroadcastChannel = useRef({})
    const refToken = useRef(auth?.token ?? {})
    const refIsRefreshing = useRef(false)
    const refPromiseRefresh = useRef(Promise.resolve())

    useEffect(
        () => {
            if (! window.BroadcastChannel) {
                return
            }

            refBroadcastChannel.current = {
                token: new BroadcastChannel(BC_TOKEN),
                user: new BroadcastChannel(BC_USER),
            }

            const onUser = ({data: user}) => {
                setAuth({user})

                if (! user) {
                    refToken.current = {}
                }
            }

            const onToken = ({data}) => refToken.current = data

            refBroadcastChannel.current.token.onmessage = onToken
            refBroadcastChannel.current.user.onmessage = onUser

            return () => {
                refBroadcastChannel.current.token.close()
                refBroadcastChannel.current.user.close()
            }
        },

        []
    )

    const syncToken = (token) => {
        refToken.current = token

        localStorage.setItem(
            STO_KEY,
            JSON.stringify({token, user: auth?.user})
        )

        refBroadcastChannel.current.token?.postMessage(token)
    }

    const syncUser = (user) => {
        setAuth({user})

        if (user) {
            const auth = {
                token: refToken.current,
                user,
            }

            localStorage.setItem(STO_KEY, JSON.stringify(auth))
            refBroadcastChannel.current.user?.postMessage(user)
        }
        else {
            refToken.current = {}
            localStorage.removeItem(STO_KEY)
            refBroadcastChannel.current.token?.postMessage({})
            refBroadcastChannel.current.user?.postMessage(null)
        }
    }

    const login = async (data) => {
        const {user, ...token} = await doLogin(data)
        syncToken(token)
        syncUser(user)
    }

    const logout = async () => syncUser(null)

    const refreshToken = async () => {
        try {
            refIsRefreshing.current = true
            refPromiseRefresh.current = doRefreshToken(refToken.current)
            refToken.current = await refPromiseRefresh.current
            syncToken(refToken.current)
        }
        catch (err) {
            logout()
            throw err
        }
        finally {
            refIsRefreshing.current = false
        }
    }

    const withToken = async (fn) => {
        if (refIsRefreshing.current) {
            await refPromiseRefresh.current
        }

        try {
            return await fn(refToken.current.token)
        }
        catch (err) {
            if ('00000010' === err.code) {
                if (! refIsRefreshing.current) {
                    refreshToken()
                }

                await refPromiseRefresh.current
                return await fn(refToken.current.token)
            }
            else if ('00000012' === err.code) {
                logout()
            }
            else {
                throw err
            }
        }
    }

    const updateUser = (data) => syncUser({...auth.user, ...data})

    const collectPermissions = (...dicts) => {
        const permissions = {}

        for (const id2codes of dicts) {
            for (const [id, codes] of Object.entries(id2codes ?? {})) {
                if (permissions[id]) {
                    permissions[id].push(...codes)
                }
                else {
                    permissions[id] = [...codes]
                }
            }
        }

        return permissions
    }

    const {
        projectFaMap,
        projectMenuMap,
        teamFaMap,
        teamMenuMap,
    } = auth?.user ?? {}

    const projectPermission = collectPermissions(projectMenuMap, projectFaMap)
    const teamPermission = collectPermissions(teamMenuMap, teamFaMap)

    const user = {
        login,
        logout,
        projectPermission,
        teamPermission,
        updateUser,
        user: auth?.user ?? null,
    }

    return (
        <AuthContext.Provider value={user}>
            <TokenContext.Provider
                value={withToken}
                {...props}
            />
        </AuthContext.Provider>
    )
}
