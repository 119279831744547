import {css} from '@emotion/react'
import cssBlock from '@/styles/Block.mjs'

const cssFooter = css({
})

export default function Footer(props) {
    return (
        <footer
            css={[cssBlock, cssFooter]}
            {...props}
        >
        </footer>
    )
}
