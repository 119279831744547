import {useAuth} from '@/hooks/auth.jsx'
import {redirectToIndex, useAuthedLoader} from '@/scripts/route.mjs'
import Detail from './Detail.jsx'
import useChildRoutes, {indexPath} from './QuestionList/useRoutes.jsx'

const path = ':id'

export default (base) => {
    const {user} = useAuth()
    const fullPath = `${base}/${path}`

    const loader = useAuthedLoader(async ({params, request}) => {
        const redirect = redirectToIndex({
            indexPath,
            params,
            path: fullPath,
            request,
        })

        if (redirect) {
            return redirect
        }

        const {id} = params

        if (id === user.id) {
            return {user}
        }
        else {
            // TODO
            return {user}
        }
    })

    return {
        children: useChildRoutes(fullPath),
        element: <Detail />,
        loader,
        path,
    }
}

export {indexPath}
