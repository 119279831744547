import {css} from '@emotion/react'
import cssBlock from '@/styles/Block.mjs'
import {GAP_MEDIUM} from '@/styles/config.mjs'
import banner from './banner.png'
import {Typography} from "antd";

const {Title, Text} = Typography;

const cssBanner = css({
    display: 'flex',
    alignItems: 'center',
    background: `url('${banner}') center no-repeat`,
    height: 200,
})

const cssBannerSegment = css({
    display: 'flex',
    flexDirection: 'column',
    // gap: GAP_MEDIUM,
})

const cssBannerSegmentTitle = css({
    marginTop: 0,
    color: "white!important",
})

const cssBannerSegmentText = css({
    color: "white",
})

export default function Banner() {

    return (
        <div css={[cssBlock, cssBanner]}>
            <div css={cssBannerSegment}>
                <Title css={cssBannerSegmentTitle}>问答</Title>
                <Text css={cssBannerSegmentText}>提问得到快速解决、帮助他人并获得回报、从精彩问答中汲取知识...</Text>
            </div>
        </div>
    )
}
