import {AuthProvider} from '@/hooks/auth.jsx'
import useDocTitle from '@/hooks/useDocTitle.mjs'
import AntdConfig from './AntdConfig.jsx'
import SwrConfig from './SwrConfig.jsx'
import Router from './Router.jsx'

export default function App() {
    // useDocTitle(import.meta.env.VITE_APP_NAME)

    return (
        <AntdConfig>
            <SwrConfig>
                <AuthProvider>
                    <Router />
                </AuthProvider>
            </SwrConfig>
        </AntdConfig>
    )
}
