import {css} from '@emotion/react'
import {GAP_SMALL} from '@/styles/config.mjs'
import goldMedal from "./gold_medal.png"
import medal from "./medal.png"
import medal2 from "./medal2.png"
import {Typography} from "antd"

const {Text, Title} = Typography

const cssAcceptState = css({
    display: 'flex',
    alignItems: 'center',
    gap: GAP_SMALL,
})

const cssIcon = css({
    width: '3em',
    height: '3em',
})

const cssText = css({
    fontSize: 26,
    borderBottom: '2px solid black'
})

export default function AcceptState({acceptState, acceptTime, ...props}) {
    if (!acceptState) {
        return null
    }

    return (
        <div
            css={cssAcceptState}
            {...props}
        >
            <img
                css={cssIcon}
                src={goldMedal}
                title={'最佳答案'}
            />

            <Text css={cssText}>最佳回答</Text>
        </div>
    )
}
