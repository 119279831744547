import {AutoComplete as AntdAutoComplete} from 'antd'
import {useReadOnly} from './ReadOnly.mjs'
import useInputable from './useInputable.mjs'
import useSelectable from './useSelectable.mjs'

const AutoComplete = ({
    allowClear,
    changeDelay,
    compatible,
    focus,
    hottestFirst,
    label,
    options,
    parse,
    readOnly,
    searchText,
    selectOnFocus,
    test,
    title,
    transform,
    value,
    writeBack,
    onBlur,
    onChange,
    onInput,
    onSearch = onChange,
    onSelect = onChange,
    ...props
}) => {
    const gReadOnly = useReadOnly()

    const {
        callbackRef,
        handleBlur,
        handleClick,
        handleCompositionEnd,
        handleCompositionStart,
        handleInput,
        inputValue,
        setInputValue,
    } = useInputable({
        allowClear,
        changeDelay,
        compatible,
        focus,
        parse,
        readOnly: readOnly || gReadOnly,
        selectOnFocus,
        test,
        transform,
        value,
        onChange: onSearch,
        onBlur,
        onInput,
    })

    const handleSelect = (value) => {
        const {label} = antdOptions.find(
            (option) => option.value === value
        )

        setInputValue(label)
        onSelect(value)
    }

    const {
        antdOptions,
        filterOption,
        filterSort,
        handleChange,
    } = useSelectable({
        hottestFirst,
        label,
        options,
        searchText,
        setInputValue,
        title,
        writeBack,
        onChange: handleSelect,
    })

    const style = {
        display: 'block',
        width: '100%',
        color: 'inherit',
        overflow: 'hidden',
        ...props.style,

        'input': {
            textAlign: 'inherit',
        },
    }

    return (
        <div
            ref={callbackRef}
            style={{display: 'contents'}}
            onClick={handleClick}
        >
            <AntdAutoComplete
                allowClear={allowClear}
                filterOption={filterOption}
                filterSort={filterSort}
                options={antdOptions}
                showSearch
                value={inputValue}
                onBlur={handleBlur}
                onChange={onChange}
                onCompositionEnd={handleCompositionEnd}
                onCompositionStart={handleCompositionStart}
                onInput={handleInput}
                onSelect={handleChange}
                {...props}
                style={style}
            />
        </div>
    )
}

export default AutoComplete
