import CustomError from '@/scripts/CustomError.mjs'
import deepEqual from './deepEqual.mjs'

export class NetworkError extends CustomError {
    constructor(status, statusText) {
        super(`${status}: ${statusText}`)
        this.status = status
    }
}

export class ServerError extends CustomError {
    constructor(code, message) {
        super(message)
        this.code = code
    }
}

const fetch = async (resource, init) => {
    const response = await window.fetch(resource, init)

    if (! response.ok) {
        const {status, statusText} = response
        throw new NetworkError(status, statusText)
    }

    const contentType = response.headers.get('Content-Type')

    if (/json/.test(contentType)) {
        const {code, message, responseBody} = await response.json()

        if ('00000000' !== code) {
            throw new ServerError(code, message)
        }

        return responseBody
    }
    else {
        const blob = await response.blob()

        const fileName = (() => {
            const cd = response.headers.get('Content-Disposition')

            if (cd) {
                const match = cd.match(/filename=(\S+)/)

                if (match) {
                    return window.decodeURIComponent(match[1])
                }
            }

            return ''
        })()

        return {blob, fileName}
    }
}

const requestPool = (() => {
    const pool = new Map()

    return {
        delete: (promise) => {
            pool.delete(promise)
        },

        get: (request) => {
            for (const [promise, req] of pool.entries()) {
                if (deepEqual(req, request)) {
                    return promise
                }
            }
        },

        set: (request, promise) => {
            pool.set(promise, request)
        },
    }
})()

const doRequest = ({headers, method, payload, url}) => {
    const resource = new window.URL(url)

    const init = {
        headers: {
            'Accept': 'application/json;charset=UTF-8',
            'Accept-Encoding':'gzip,deflate',

            ...Object.fromEntries(
                Object.entries(headers).filter(
                    ([k, v]) => undefined !== v && null !== v
                )
            )
        },

        method,
    }

    if ('GET' === method || 'HEAD' === method) {
        for (const [key, value] of Object.entries(payload)) {
            if (undefined !== value && null !== value) {
                if (Array.isArray(value)) {
                    for (var v of value) {
                        resource.searchParams.append(key, v)
                    }
                }
                else {
                    resource.searchParams.set(key, value)
                }
            }
        }
    }
    else if (headers['Content-Type']?.startsWith('multipart/form-data')) {
        delete init.headers['Content-Type']
        const body = new FormData()

        for (const [key, value] of Object.entries(payload)) {
            if (Array.isArray(value)) {
                for (const e of value) {
                    body.append(key, e)
                }
            }
            else {
                body.append(key, value)
            }
        }

        init.body = body
    }
    else {
        init.headers['Content-Type'] = 'application/json;charset=UTF-8'
        init.body = JSON.stringify(payload)
    }

    return fetch(resource, init)
}

export default async ({
    headers = {},
    method,
    payload = {},
    url,
}) => {
    const tuple = {headers, method, payload, url}
    const reusablePromise = requestPool.get(tuple)

    if (reusablePromise) {
        return reusablePromise
    }

    const promise = doRequest(tuple)
    requestPool.set(tuple, promise)

    ;(async () => {
        try {
            await promise
        }
        finally {
            requestPool.delete(promise)
        }
    })()

    return promise
}
