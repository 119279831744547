import {redirect} from 'react-router-dom'
import useApiTopic from '@/hooks/useApiTopic.mjs'
import {useAuth} from '@/hooks/auth.jsx'
import {useAuthedLoader} from '@/scripts/route.mjs'
import runAsync from '@/scripts/runAsync.mjs'
import Edit from './Edit.jsx'

const path = ':id/edit'

export default () => {
    const {user} = useAuth()
    const {readTopic} = useApiTopic()

    const loader = async ({params}) => {
        const {id} = params
        const question = await runAsync(() => readTopic({id}))

        if (question.authorId === user.id) {
            return {question}
        }
        else {
            return redirect(`/questions/${id}`)
        }
    }

    return {
        element: <Edit />,
        loader: useAuthedLoader(loader),
        path,
    }
}
