import {redirectToIndex} from '@/scripts/route.mjs'
import List from './List.jsx'
import useChildRoutes, {indexPath} from './QuestionList/useRoutes.jsx'

export default (base) => {
    return {
        children: useChildRoutes(base),
        element: <List />,

        loader: ({params, request}) => redirectToIndex({
            indexPath,
            path: base,
            params,
            request,
        }),

        path: '',
    }
}

export {indexPath}
