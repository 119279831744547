import {useNavigate, useNavigation, useSubmit} from 'react-router-dom'
import {Button} from 'antd'
import {ExclamationOutlined} from '@ant-design/icons'

export default function ButtonReport({...props}) {
    const navigate = useNavigate()
    const navigation = useNavigation()
    const submit = useSubmit()

    return (
        <Button
            icon={<ExclamationOutlined />}
            loading={'submitting' === navigation.state}
            type="text"
            {...props}
        >
            举报
        </Button>
    )
}
