import {NavLink} from 'react-router-dom'
import {css} from '@emotion/react'
import {useUpdatePath} from '@/scripts/route.mjs'
import {cssLink} from '@/styles/Link.mjs'
import Icon from '@ant-design/icons';
import {Space, theme} from 'antd'

const HotIcon = (props) => {
    const svg = () => (
        <svg t="1687858941851" className="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="5908" xmlns:xlink="http://www.w3.org/1999/xlink" width="32" height="32">
            <path d="M660.48 721.28" fill="#66180C" p-id="5909"></path>
            <path
                d="M910.98112 724.29056 113.01888 724.29056c-38.48704 0-69.79584-31.3088-69.79584-69.79584L43.22304 369.5104c0-38.48704 31.3088-69.80096 69.79584-69.80096l797.96224 0c38.48704 0 69.79584 31.31392 69.79584 69.80096l0 284.98432C980.77696 692.98176 949.46304 724.29056 910.98112 724.29056zM113.01888 325.30944c-24.3712 0-44.19584 19.82976-44.19584 44.20096l0 284.98432c0 24.3712 19.82464 44.19584 44.19584 44.19584l797.96224 0c24.3712 0 44.19584-19.82464 44.19584-44.19584L955.17696 369.5104c0-24.3712-19.82464-44.20096-44.19584-44.20096L113.01888 325.30944z"
                fill="#E24826" p-id="5910"></path>
            <path d="M144.86016 645.44256l0-261.9392 34.6624 0 0 107.56096 136.14592 0L315.66848 383.50848l34.6624 0 0 261.9392-34.6624 0 0-123.46368L179.52256 521.984l0 123.46368L144.86016 645.44768z" fill="#E24826" p-id="5911"></path>
            <path
                d="M397.50144 517.86752c0-43.47392 11.6736-77.51168 35.0208-102.11328 23.3472-24.59648 53.48352-36.89472 90.40896-36.89472 24.18176 0 45.9776 5.78048 65.39264 17.3312 19.41504 11.55584 34.21696 27.66336 44.40064 48.32768 10.18368 20.66944 15.27808 44.10368 15.27808 70.30784 0 26.56256-5.36064 50.32448-16.08192 71.29088-10.7264 20.9664-25.91232 36.83328-45.56288 47.616s-40.85248 16.16896-63.61088 16.16896c-24.6528 0-46.6944-5.95456-66.10432-17.86368-19.41504-11.90912-34.12992-28.17024-44.1344-48.77824C402.5088 562.6624 397.50144 540.86144 397.50144 517.86752zM433.23904 518.40512c0 31.56992 8.48896 56.43264 25.46176 74.5984 16.9728 18.16576 38.26176 27.24352 63.87712 27.24352 26.0864 0 47.55456-9.16992 64.4096-27.51488 16.85504-18.33984 25.28256-44.36992 25.28256-78.08 0-21.3248-3.60448-39.936-10.80832-55.83872-7.20896-15.8976-17.75104-28.23168-31.62624-36.98688-13.88032-8.7552-29.45536-13.1328-46.72512-13.1328-24.54016 0-45.64992 8.42752-63.33952 25.28256C442.08128 450.8416 433.23904 478.98112 433.23904 518.40512z"
                fill="#E24826" p-id="5912"></path>
            <path d="M759.31648 645.44256 759.31648 414.41792l-86.2976 0 0-30.90944 207.62112 0 0 30.90944-86.656 0 0 231.02976L759.31648 645.44768z" fill="#E24826" p-id="5913"></path>
        </svg>
    )
    return <Icon component={svg} {...props} />
};

const WaitIcon = (props) => {
    const svg = () => (
        <svg t="1687858296843" className="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="10754" xmlns:xlink="http://www.w3.org/1999/xlink" width="32" height="20">
            <path
                d="M768 68.266667H256a68.266667 68.266667 0 0 0-68.266667 68.266666v85.521067l0.085334 6.5536a238.9504 238.9504 0 0 0 61.320533 153.344l117.162667 130.0992-117.128534 129.962667A239.0016 239.0016 0 0 0 187.733333 801.962667V887.466667a68.266667 68.266667 0 0 0 68.266667 68.266666h512a68.266667 68.266667 0 0 0 68.266667-68.266666v-85.486934l-0.085334-6.5536a239.0016 239.0016 0 0 0-61.354666-153.412266l-117.1456-129.962667 117.179733-130.0992A238.9504 238.9504 0 0 0 836.266667 222.0544V136.533333a68.266667 68.266667 0 0 0-68.266667-68.266666zM256 136.533333h512v85.521067c0 42.171733-15.633067 82.875733-43.861333 114.2272l-137.762134 152.951467a34.133333 34.133333 0 0 0 0 45.687466l137.728 152.797867A170.734933 170.734933 0 0 1 768 801.979733V887.466667H256v-85.486934c0-42.1888 15.650133-82.909867 43.895467-114.261333l137.728-152.797867a34.133333 34.133333 0 0 0 0-45.687466l-137.762134-152.951467A170.683733 170.683733 0 0 1 256 222.037333V136.533333z"
                fill="#ea9518" p-id="10755" data-spm-anchor-id="a313x.7781069.0.i1" className=""></path>
            <path d="M682.666667 750.933333v34.133334a34.133333 34.133333 0 0 1-34.133334 34.133333H375.466667a34.133333 34.133333 0 0 1-34.133334-34.133333v-34.133334h341.333334z" fill="#00B386" p-id="10756"></path>
        </svg>
    )
    return <Icon component={svg} {...props} />
};

const FinishedIcon = (props) => {
    const svg = () => (
        <svg t="1687858842513" className="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="4689" data-spm-anchor-id="a313x.7781069.0.i6" xmlns:xlink="http://www.w3.org/1999/xlink" width="32" height="20">
            <path
                d="M512 962c-60.74 0-119.68-11.9-175.17-35.37-53.59-22.67-101.71-55.11-143.03-96.43-41.32-41.32-73.76-89.44-96.43-143.03C73.9 631.68 62 572.75 62 512s11.9-119.68 35.37-175.17c22.67-53.59 55.11-101.71 96.43-143.03 41.32-41.32 89.44-73.76 143.03-96.43C392.32 73.9 451.26 62 512 62s119.67 11.9 175.17 35.37c53.59 22.67 101.71 55.11 143.03 96.43 41.32 41.32 73.76 89.44 96.43 143.03C950.1 392.32 962 451.26 962 512s-11.9 119.67-35.37 175.17c-22.67 53.59-55.11 101.71-96.43 143.03-41.32 41.32-89.44 73.76-143.03 96.43C631.68 950.1 572.75 962 512 962z m0-860c-226.07 0-410 183.93-410 410s183.93 410 410 410 410-183.92 410-410-183.92-410-410-410z m-44.35 573.93l274.64-274.64c7.81-7.81 7.81-20.47 0-28.28s-20.47-7.81-28.29 0l-260.5 260.5-141.74-141.74c-7.81-7.81-20.47-7.81-28.28 0s-7.81 20.47 0 28.28l155.88 155.88c3.75 3.75 8.84 5.86 14.14 5.86s10.39-2.11 14.14-5.86z"
                p-id="4690" data-spm-anchor-id="a313x.7781069.0.i5" className="" fill="#11f58e"></path>
        </svg>
    )
    return <Icon component={svg} {...props} />
};

export default function SelectQuestionCategory(props) {
    const {useToken} = theme
    const {token} = useToken()

    const cssSelect = css({
        display: 'flex',
        // gap:8
        // gridTemplateColumns: 'repeat(3, 80px)',
        // marginTop: - GAP_MEDIUM,
        // marginBottom: - GAP_MEDIUM,
    })

    const cssOption = css({
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderBottom: '2px solid transparent',
        padding: '16px 16px',
        fontWeight: 'bold',
        '&.active': {
            color: token.colorPrimary,
            // borderBottomColor: 'currentColor',
        },
        '&:hover': {
            color: token.colorPrimary,
        },
    })

    const updatePath = useUpdatePath()

    return (
        <div
            css={cssSelect}
            {...props}
        >
            <NavLink
                css={[cssLink, cssOption]}
                to={updatePath('latest', {pageNumber: 1})}
            >
                <Space>
                    <HotIcon/>
                    最新
                </Space>
            </NavLink>

            <NavLink
                css={[cssLink, cssOption]}
                to={updatePath('unanswered', {pageNumber: 1})}
            >
                <Space>
                    <WaitIcon/>
                    待解决
                </Space>
            </NavLink>

            <NavLink
                css={[cssLink, cssOption]}
                to={updatePath('accepted', {pageNumber: 1})}
            >
                <Space>
                    <FinishedIcon/>
                    已解决
                </Space>
            </NavLink>
        </div>
    )
}
