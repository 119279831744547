import {redirectToIndex} from '@/scripts/route.mjs'
import Search from './Search.jsx'
import useRoutesQuestions, {indexPath} from './Questions/useRoutes.jsx'
//import useRoutesUsers from './Users/useRoutes.jsx'

const path = 'search'

export default (base) => {
    const fullPath = `${base}/${path}`

    const children = [
        useRoutesQuestions(fullPath),
        //useRoutesUsers(fullPath),
    ]

    return {
        children,
        element: <Search />,

        loader: ({params, request}) => redirectToIndex({
            indexPath,
            params,
            path: fullPath,
            request,
        }),

        path,
    }
}
