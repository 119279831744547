import {Outlet, useLocation} from 'react-router-dom'
import {css} from '@emotion/react'
import cssBlock from '@/styles/Block.mjs'
import {GAP_MEDIUM} from '@/styles/config.mjs'
import Aside from './Aside/Aside.jsx'
import Banner from "./Banner/Banner.jsx";

const cssQuestions = css({
    display: 'grid',
    gridTemplateColumns: '1fr 300px',
    gap: GAP_MEDIUM,
    backgroundColor: '#f4f5f5',
    paddingTop: GAP_MEDIUM,
    paddingBottom: GAP_MEDIUM,

    '@media (max-width: 899px)': {
        display: 'block',
    },
})

const cssAside = css({
    '@media (max-width: 899px)': {
        display: 'none',
    },
})

export default function Questions(props) {
    const location_ = useLocation();
    return (
        <>
            {/*{location_.pathname.startsWith('/questions/latest') && <Banner/> }*/}
            <Banner/>
            <div
                css={[cssBlock, cssQuestions]}
                {...props}
            >
                <Outlet />
                <Aside css={cssAside} />
            </div>
        </>
    )
}
