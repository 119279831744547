import {useState} from 'react'
import {useNavigation, useSubmit} from 'react-router-dom'
import {css} from '@emotion/react'
import {Button} from 'antd'
import RichTextEditor from '@/components/RichTextEditor/RichTextEditor.jsx'
import {GAP_LARGE} from '@/styles/config.mjs'

const cssForm = css({
    display: 'flex',
    flexDirection: 'column',
    gap: GAP_LARGE,
})

export default function FormAnswer({issueMainId, ...props}) {
    const navigation = useNavigation()
    const submit = useSubmit()
    const [formData, setFormData] = useState({})

    const handleChangeDetail = (content) => {
        setFormData((formData) => ({...formData, content}))
    }

    const handleClickSubmit = () => {
        const fd = new FormData()
        fd.set('issueMainId', issueMainId)

        for (const [k, v] of Object.entries(formData)) {
            fd.set(k, v)
        }

        submit(fd, {action: 'answers', method: 'POST'})
    }

    return (
        <div
            css={cssForm}
            {...props}
        >
            <RichTextEditor onChange={handleChangeDetail} />

            <Button
                style={{alignSelf: 'flex-start'}}
                disabled={! formData.content}
                loading={'submitting' === navigation.state}
                size="large"
                type="primary"
                onClick={handleClickSubmit}
            >
                发布回答
            </Button>
        </div>
    )
}
