import {useNavigation, useSubmit} from 'react-router-dom'
import {Button} from 'antd'
import {CheckOutlined} from '@ant-design/icons'
import PostPermission from '../PostPermission.jsx'

export default function ButtonAccept({postId, ...props}) {
    const navigation = useNavigation()
    const submit = useSubmit()

    const handleClick = () => {
        const fd = new FormData()
        fd.set('id', postId)
        submit(fd, {action: 'accept', method: 'PUT'})
    }

    return (
        <PostPermission every={['accept']}>
            <Button
                icon={<CheckOutlined />}
                type='primary'
                loading={'submitting' === navigation.state}
                onClick={handleClick}
                {...props}
            >
                采纳回答
            </Button>
        </PostPermission>
    )
}
