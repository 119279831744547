import {useNavigate} from 'react-router-dom'
import {Button} from 'antd'
import PostPermission from '../PostPermission.jsx'
import {EditOutlined} from "@ant-design/icons";

export default function ButtonEdit({questionId, ...props}) {
    const navigate = useNavigate()

    const handleClick = () => {
        navigate(`/questions/${questionId}/edit`)
    }

    return (
        <PostPermission every={['edit']}>
            <Button
                icon={<EditOutlined />}
                ghost
                type="primary"
                onClick={handleClick}
                {...props}
            >编辑</Button>
        </PostPermission>
    )
}
