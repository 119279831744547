import {redirectToIndex} from '@/scripts/route.mjs'
import Main from './Main.jsx'
import useRoutesQuestions, {indexPath} from './Questions/useRoutes.jsx'
import useRoutesSearch from './Search/useRoutes.jsx'
import useRoutesUsers from './Users/useRoutes.jsx'
import useRoutesCommunityNotices from './CommunityNotices/useRoutes.jsx'
const path = '/'

export default () => {
    const children = [
        useRoutesQuestions(''),
        useRoutesSearch(''),
        useRoutesUsers(''),
        useRoutesCommunityNotices(''),
    ]

    return {
        children,
        element: <Main />,

        loader: ({params, request}) => redirectToIndex({
            indexPath, path, params, request
        }),

        path,
    }
}
