import {useState} from 'react'
import {useLoaderData, useNavigate, useSubmit} from 'react-router-dom'
import {Button} from 'antd'
import {cssQuesion} from '../Ask/cssQuestion.mjs'
import Detail from '../Ask/Detail.jsx'
import Header from '../Ask/Header.jsx'
import Title from '../Ask/Title.jsx'
import Type from '../Ask/Type.jsx'

export default function EditQuestion(props) {
    const {question: {content, id, issueTypeId, title}} = useLoaderData()
    const navigate = useNavigate()
    const submit = useSubmit()
    const [formData, setFormData] = useState({content, issueTypeId, title})

    const handleChangeDetail = (content) => {
        setFormData((formData) => ({...formData, content}))
    }

    const handleChangeTitle = (title) => {
        setFormData((formData) => ({...formData, title}))
    }

    const handleChangeType = (issueTypeId) => {
        setFormData((formData) => ({...formData, issueTypeId}))
    }

    const handleClickSubmit = async () => {
        const fd = new FormData()
        fd.set('id', id)

        for (const [k, v] of Object.entries(formData)) {
            fd.set(k, v)
        }

        submit(fd, {action: `/questions/${id}`, method: 'PUT'})
    }

    return (
        <div
            css={cssQuesion}
            {...props}
        >
            <Header />

            <Title
                value={formData.title}
                onChange={handleChangeTitle}
            />

            <Detail
                value={formData.content}
                onChange={handleChangeDetail}
            />

            <Type
                value={formData.issueTypeId}
                onChange={handleChangeType}
            />

            <Button
                style={{alignSelf: 'flex-start'}}

                disabled={
                    ! (
                        formData.content &&
                        formData.issueTypeId &&
                        formData.title
                    )
                }

                loading={'submitting' === navigate.state}
                size="large"
                type="primary"
                onClick={handleClickSubmit}
            >
                保存
            </Button>
        </div>
    )
}
