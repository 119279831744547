import {css} from '@emotion/react'
import {GAP_SMALL} from '@/styles/config.mjs'
import {cssLink} from '@/styles/Link.mjs'
import logo from '@/assets/logo.png'

const cssLogo = css({
    display: 'flex',
    alignItems: 'center',
    gap: GAP_SMALL,

    img: {
        width: 35,
    },
})

const cssAppName = css({
    color: '#000',
    fontSize: 20,

    '@media (max-width: 899px)': {
        display: 'none',
    }
})

export default function Logo(props) {
    return (
        <a
            css={[cssLink, cssLogo]}
            href="/"
            {...props}
        >
            <img
                alt="logo"
                src={logo}
            />

            <span css={cssAppName}>软件地图社区</span>
        </a>
    )
}
