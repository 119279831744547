import {Link} from 'react-router-dom'
import {css} from '@emotion/react'
import {cssLink, cssLinkSecondary} from '@/styles/Link.mjs'
import {GAP_MEDIUM} from "@/styles/config.mjs";
import useHttp from '@/hooks/useHttp.mjs'
import runAsync from "@/scripts/runAsync.mjs";
import {useEffect, useState} from "react";
import {theme} from "antd";

const cssCommunityNoticeItem = css({
    display: 'block',
    lineHeight: 1.6,
    padding: GAP_MEDIUM,
})

const CommunityNoticeItem = ({id, ...props}) => {
    const {useToken} = theme
    const {token} = useToken()
    const cssHover = {
        '&:hover': {
            color: token.colorPrimary
        }
    }

    return (
        <Link
            css={[cssLink, cssLinkSecondary, cssCommunityNoticeItem, cssHover]}
            target="_blank"
            to={`/communityNotices/detail?id=${id}`}
            {...props}
        />
    )
}

const cssCommunityNotices = css({
    display: 'flex',
    flexDirection: 'column',
    // padding: GAP_MEDIUM,
    backgroundColor: 'white',
})

export default function CommunityNotices() {
    const http = useHttp()
    const [data, setData] = useState([])

    useEffect(() => {
        (async () => {
            const pagingWrapper = await runAsync(
                async () => {
                    return await http.get(`/communityNotice/pagingCommunityNotice`, {pageNumber: 1, pageSize: 5})
                },
                {
                    error: {content: (err) => `查询公告失败: ${err.message}`},
                    loading: {show: false},
                    success: {show: false},
                }
            );
            setData(pagingWrapper.data)
        })()
    }, [])

    return (
        <div css={cssCommunityNotices}>
            {
                data.map(({id, title}) => (
                    <CommunityNoticeItem id={id}>{title}</CommunityNoticeItem>
                ))
            }
        </div>
    )
}
