import {NavLink} from 'react-router-dom'
import {css} from '@emotion/react'
import {cssButton} from '@/styles/Button.mjs'

import {
    GAP_MEDIUM,
    TEXT_COLOR_SECONDARY,
    TEXT_COLOR_PRIMARY
} from '@/styles/config.mjs'

const cssNav = css({
    display: 'flex',
    gap: GAP_MEDIUM,
})

const cssLink = css({
    color: TEXT_COLOR_SECONDARY,
    border: 'none',
    borderRadius: 9999,

    '&.active': {
        color: TEXT_COLOR_PRIMARY,
    }
})

export default function Nav(props) {
    return (
        <div
            css={cssNav}
            {...props}
        >
            <NavLink
                css={[cssButton, cssLink]}
                to="/questions/"
            >问答</NavLink>
        </div>
    )
}
