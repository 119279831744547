import Questions from './Questions.jsx'
import useRoutesAsk from './Ask/useRoutes.jsx'
import useRoutesDetail from './Detail/useRoutes.jsx'
import useRoutesEdit from './Edit/useRoutes.jsx'
import useRoutesList, {indexPath as nestedIndexPath} from './List/useRoutes.jsx'

const path = 'questions'

export default (base) => {
    const fullPath = `${base}/${path}`

    const children = [
        useRoutesAsk(fullPath),
        useRoutesEdit(fullPath),
        useRoutesList(fullPath),
        useRoutesDetail(fullPath),
    ]

    return {
        children,
        element: <Questions />,
        path,
    }
}

export const indexPath = `${path}/${nestedIndexPath}`
