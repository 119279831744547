import {SWRConfig} from 'swr'

const swrOptions = {
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
}

export default function SwrConfig(props) {
    return (
        <SWRConfig
            value={swrOptions}
            {...props}
        />
    )
}
