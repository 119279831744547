import {generatePath, redirect} from 'react-router-dom'
import useApiTopic from '@/hooks/useApiTopic.mjs'
import runAsync from '@/scripts/runAsync.mjs'
import Detail from './Detail.jsx'

export default (base) => {
    const {
        acceptAnswer,
        createAnswer,
        createComment,
        favoriteTopic,
        followTopic,
        readTopic,
        unfavoriteTopic,
        unfollowTopic,
        updateAnswer,
        updateTopic,
        viewTopic,
    } = useApiTopic()

    const path = ':id'

    const children = [
        {
            action: async ({request}) => {
                if ('PUT' === request.method) {
                    const formData = await request.formData()

                    try {
                        await runAsync(() => acceptAnswer(
                            Object.fromEntries(formData.entries())
                        ))
                    }
                    catch {
                    }

                    return redirect(location.href)
                }
            },

            element: <></>,
            path: 'accept',
        },

        {
            action: async ({request}) => {
                if ('POST' === request.method) {
                    const formData = await request.formData()

                    try {
                        await runAsync(() => createAnswer(
                            Object.fromEntries(formData.entries())
                        ))
                    }
                    catch {
                    }

                    return redirect(location.href)
                }
            },

            element: <></>,
            path: 'answers',
        },

        {
            action: async ({request}) => {
                if ('POST' === request.method) {
                    const formData = await request.formData()

                    try {
                        await runAsync(() => createComment(
                            Object.fromEntries(formData.entries())
                        ))
                    }
                    catch {
                    }

                    return redirect(location.href)
                }
                else if ('PUT' === request.method) {
                    const formData = await request.formData()

                    try {
                        await runAsync(() => updateAnswer(
                            Object.fromEntries(formData.entries())
                        ))
                    }
                    catch {
                    }

                    return redirect(location.href)
                }
            },

            element: <></>,
            path: 'comments/:id?',
        },

        {
            action: async ({request}) => {
                if ('PUT' === request.method) {
                    const formData = await request.formData()

                    try {
                        await runAsync(() => favoriteTopic(
                            Object.fromEntries(formData.entries())
                        ))
                    }
                    catch {
                    }

                    return redirect(location.href)
                }
            },

            element: <></>,
            path: 'favorite',
        },

        {
            action: async ({request}) => {
                if ('PUT' === request.method) {
                    const formData = await request.formData()

                    try {
                        await runAsync(() => followTopic(
                            Object.fromEntries(formData.entries())
                        ))
                    }
                    catch {
                    }

                    return redirect(location.href)
                }
            },

            element: <></>,
            path: 'follow',
        },

        {
            action: async ({request}) => {
                if ('PUT' === request.method) {
                    const formData = await request.formData()

                    try {
                        await runAsync(() => unfavoriteTopic(
                            Object.fromEntries(formData.entries())
                        ))
                    }
                    catch {
                    }

                    return redirect(location.href)
                }
            },

            element: <></>,
            path: 'unfavorite',
        },

        {
            action: async ({request}) => {
                if ('PUT' === request.method) {
                    const formData = await request.formData()

                    try {
                        await runAsync(() => unfollowTopic(
                            Object.fromEntries(formData.entries())
                        ))
                    }
                    catch {
                    }

                    return redirect(location.href)
                }
            },

            element: <></>,
            path: 'unfollow',
        },
    ]

    const action = async ({params, request}) => {
        if ('PUT' === request.method) {
            const formData = await request.formData()

            try {
                await runAsync(() => updateTopic(
                    Object.fromEntries(formData.entries())
                ))

                return redirect(
                    generatePath(`${base}/${path}`, params)
                )
            }
            catch {
                return redirect(location.href)
            }
        }
    }

    const loader = async ({params, request}) => {
        const {id} = params
        viewTopic({issueMainId: id})
        const topic = await runAsync(() => readTopic({id}))
        const {searchParams} = new URL(request.url)
        const writeCommentId = searchParams.get('write-comment')

        return {
            topic,
            writeCommentId: writeCommentId ? Number(writeCommentId) : null,
        }
    }

    return {
        action,
        children,
        element: <Detail />,
        loader,
        path,
    }
}
