import {useMemo} from 'react'
import {useToken} from '@/hooks/auth.jsx'
import httpRequest from '@/scripts/http.mjs'
import {join} from '@/scripts/url'

export {NetworkError, ServerError} from '@/scripts/http.mjs'

const normalizeUrl = (url) => {
    if (! url) {
        return import.meta.env.VITE_HTTP_BASE_URL
    }

    if (! url.startsWith('/')) {
        return url
    }

    return join(import.meta.env.VITE_HTTP_BASE_URL, url)
}

export default () => {
    const withToken = useToken()

    return useMemo(
        () => {
            const request = ({headers, url, ...options}) => withToken(
                (token) => httpRequest({
                    headers: {
                        ...headers,
                        __TOKEN__: token,
                    },

                    url: normalizeUrl(url),
                    ...options,
                })
            )

            const upload = (
                payload = {},
                options = {}
            ) => request({
                headers: {
                    ...options.headers,
                    'Content-Type': 'multipart/form-data',
                },

                method: 'POST',

                payload: {
                    contentType: payload.file?.type,
                    ...payload,

                    pathName: payload.pathName?.startsWith('/') ?
                        payload.pathName : `/${payload.pathName}`,
                },

                url: '/files',
                ...options,
            })

            return {
                request,
                upload,

                ...Object.fromEntries(
                    ['delete', 'get', 'post', 'put'].map(
                        (methodName) => [
                            methodName,

                            (url, payload, options = {}) => request({
                                ...options,
                                method: methodName.toUpperCase(),
                                payload,
                                url,
                            }),
                        ]
                    )
                ),
            }
        },

        [withToken]
    )
}
