import {useLoaderData} from 'react-router-dom'
import {css} from '@emotion/react'
import {GAP_LARGE} from '@/styles/config.mjs'
import {PostPermissionProvider} from './PostPermission.jsx'
import Answers from './Answers/Answers.jsx'
import Question from './Question/Question.jsx'
import WriteAnswer from './WriteAnswer/WriteAnswer.jsx'
import useDocTitle from "@/hooks/useDocTitle.mjs";

const cssMain = css({
    display: 'flex',
    flexDirection: 'column',
    gap: GAP_LARGE,
})

export default function Detail(props) {
    const {topic} = useLoaderData()
    useDocTitle(topic.title)

    return (
        <main css={cssMain} {...props}>
            <PostPermissionProvider
                post={topic}
                topic={topic}
            >
                <Question data={topic} />
                <Answers topic={topic} />
                <WriteAnswer issueMainId={topic.id} />
            </PostPermissionProvider>
        </main>
    )
}
