import {Link} from 'react-router-dom'
import {css} from '@emotion/react'
import User from '@/components/User/User.jsx'
import {useAuth} from '@/hooks/auth.jsx'
import {cssButton, cssButtonPrimary} from '@/styles/Button.mjs'

import {
    FONT_SIZE_PRIMARY,
    FONT_SIZE_SECONDARY,
    GAP_SMALL,
    TEXT_COLOR_IMPORTANT,
    TEXT_COLOR_SECONDARY,
} from '@/styles/config.mjs'

import {cssLink} from '@/styles/Link.mjs'
import {theme} from "antd";

const cssAccount = css({
    display: 'flex',
    alignItems: 'center',
})

const cssUser = css({
    color: TEXT_COLOR_IMPORTANT,
    fontSize: FONT_SIZE_PRIMARY,
    fontWeight: 600,
})


const cssLinkLogout = css({
    color: TEXT_COLOR_SECONDARY,
    fontSize: FONT_SIZE_SECONDARY,
    marginLeft: GAP_SMALL,
})

export default function Account(props) {
    const {logout, user} = useAuth()
    const {useToken} = theme
    const {token} = useToken()

    const cssLogin = css({
        color: token.colorPrimary,
        fontWeight: 700
    })

    if (user) {
        return (
            <div
                css={cssAccount}
                {...props}
            >
                <User
                    css={cssUser}
                    avatar={user.avatar}
                    name={user.nickname}
                    userId={user.id}
                />

                <a
                    css={[cssLink, cssLinkLogout]}
                    onClick={logout}
                >
                    退出登录
                </a>
            </div>
        )
    }
    else {
        return (
            <Link
                css={[cssLink, cssLogin]}
                to="/login?r=-1"
                {...props}
            >
                登录
            </Link>
        )
    }
}
