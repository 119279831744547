import CommunityNotices from './CommunityNotices.jsx'
import useRoutesList from './List/useRoutes.jsx'
import useRoutesDetail from './Detail/useRoutes.jsx'

const path = 'communityNotices'

export default (base) => {
    const fullPath = `${base}/${path}`

    const children = [
        useRoutesList(fullPath),
        useRoutesDetail(fullPath)
    ]

    return {
        children,
        element: <CommunityNotices/>,
        path,
    }
}

