import {NavLink} from 'react-router-dom'
import {css} from '@emotion/react'
import {GAP_MEDIUM} from '@/styles/config.mjs'
import {cssLink} from '@/styles/Link.mjs'

const cssActivities = css({
    display: 'grid',
    gridTemplateColumns: 'repeat(4, 80px)',
})

const cssOption = css({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderBottom: '2px solid transparent',

    '&.active': {
        color: '#3d7fff',
        borderBottomColor: 'currentColor',
    },
})

export default function Activities({user, ...props}) {
    return (
        <div
            css={cssActivities}
            {...props}
        >
            <NavLink
                css={[cssLink, cssOption]}
                to="commented-questions"
            >回答</NavLink>

            <NavLink
                css={[cssLink, cssOption]}
                to="asked-questions"
            >问题</NavLink>

            <NavLink
                css={[cssLink, cssOption]}
                to="favorited-questions"
            >收藏</NavLink>

            <NavLink
                css={[cssLink, cssOption]}
                to="followed-questions"
            >关注</NavLink>
        </div>
    )
}
