import fingerprint from '@/scripts/fingerprint.mjs'
import useHttp from './useHttp.mjs'
import {useAuth} from './auth.jsx'

export default () => {
    const http = useHttp()
    const {user, updateUser} = useAuth()

    const updateUserStat = (stat, delta) => {
        const {userCustomerCountDto: {[stat]: count}} = user

        updateUser({
            userCustomerCountDto: {
                ...user.userCustomerCountDto,
                [stat]: count + delta,
            },
        })
    }

    return {
        acceptAnswer(data) {
            return http.post('/issue/acceptIssueComment', data)
        },

        async createAnswer(data) {
            const result = await http.post('/issue/addIssueComment', data)
            updateUserStat('issueCommentCount', 1)
            return result
        },

        createComment(data) {
            return http.post('/issue/addIssueComment', data)
        },

        async createTopic(data) {
            const result = await http.post('/issue/addIssueMain', data)
            updateUserStat('issueMainCount', 1)
            return result
        },

        async favoriteTopic(args) {
            const result = await http.post('/issue/issueMainFavorite', args)
            updateUserStat('favoriteCount', 1)
            return result
        },

        async followTopic(args) {
            const result = await http.post('/issue/issueMainFollow', args)
            updateUserStat('followCount', 1)
            return result
        },

        listTopics(args) {
            return http.get('/issue/pagingIssueMain', args)
        },

        readTopic(args) {
            return http.get('/issue/oneIssueMain', args)
        },

        async unfavoriteTopic(args) {
            const result = await http.post('/issue/issueMainFavorite', args)
            updateUserStat('favoriteCount', -1)
            return result
        },

        async unfollowTopic(args) {
            const result = await http.post('/issue/issueMainFollow', args)
            updateUserStat('followCount', -1)
            return result
        },

        updateAnswer(data) {
            return http.post('/issue/modifyIssueComment', data)
        },

        updateTopic(data) {
            return http.post('/issue/modifyIssueMain', data)
        },

        async viewTopic(data) {
            const cid = await fingerprint()
            return http.post('/issue/addIssueMainViewCount', {...data, cid})
        },
    }
}
