import {useState} from 'react'
import {useLocation} from 'react-router-dom'
import {css} from '@emotion/react'
import useSwr from 'swr'
import {LoadingOutlined, SearchOutlined} from '@ant-design/icons'
import AutoComplete from '@/components/Form/AutoComplete.jsx'
import InputText from '@/components/Form/InputText.jsx'
import useApiTopic from '@/hooks/useApiTopic.mjs'
import {GAP_SMALL, TEXT_COLOR_TRIVIAL} from '@/styles/config.mjs'
import {cssLink, cssLinkPrimary} from '@/styles/Link.mjs'

const cssNotFound = css({
    display: 'flex',
    placeItems: 'center space-between',
    color: TEXT_COLOR_TRIVIAL,
    padding: GAP_SMALL,
})

const NotFound = () => {
    return (
        <div css={cssNotFound}>
            未找到相关内容，

            <a
                css={[cssLink, cssLinkPrimary]}
                href="/search"
                target="_blank"
            >
                试试高级搜索
            </a>
        </div>
    )
}

export default function Search(props) {
    const location = useLocation()
    const {listTopics} = useApiTopic()
    const [keyword, setKeyword] = useState('')
    const pageNumber = 1
    const pageSize = 5

    const {data, isLoading} = useSwr(
        keyword ? `search/questions?keyword=${keyword}&pageNumber=${pageNumber}&pageSize=${pageSize}` : null,
        () => listTopics({keyword, pageNumber: 1, pageSize: 5})
    )

    const options = (data?.data ?? []).map(
        ({id, title}) => ([id, title])
    )

    if (location.pathname.startsWith('/search')) {
        return <div></div>
    }


    if (0 < options.length) {
        const text = data.data.length < data.totalRecord ?
            '查看更多结果' : '高级搜索'

        const label = (
            <a
                css={[cssLink, cssLinkPrimary]}
                href={`/search?keyword=${keyword}`}
                target="_blank"
                onClick={(e) => e.stopPropagation()}
            >
                {text}
            </a>
        )

        options.push([0, label])
    }

    const handlePressEnter = ({target: {value}}) => {
        window.open(`/search?keyword=${value}`, '_blank')
    }

    const handleSearch = (value) => {
        if ('string' === typeof value) {
            setKeyword(value.trim())
        }
    }

    const handleSelect = (id) => {
        if (id) {
            window.open(`/questions/${id}`, '_blank')
        }
    }

    return (
        <AutoComplete
            filterOption={false}
            filterSort={false}
            notFoundContent={<NotFound />}
            open={keyword}
            options={options}
            onSearch={handleSearch}
            onSelect={handleSelect}
            {...props}
        >
            <InputText
                allowClear
                changeDelay={500}
                compatible
                placeholder="搜索……"
                prefix={isLoading ? <LoadingOutlined /> : <SearchOutlined />}
                onPressEnter={handlePressEnter}
            />
        </AutoComplete>
    )
}
