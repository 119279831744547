import useApiTopic from '@/hooks/useApiTopic.mjs'
import {redirect} from 'react-router-dom'
import {makePath} from '@/scripts/route.mjs'
import QuestionList from './QuestionList.jsx'

const childPaths = [
    ['accepted', 1],
    ['latest'],
    ['unanswered', 0],
]

export default (base) => {
    const {listTopics} = useApiTopic()

    return childPaths.map(([path, state]) => {
        const routePath = `${path}/:pageNumber?`
        const fullPath = `${base}/${routePath}`

        return {
            element: <QuestionList path={fullPath} />,

            loader: async ({params, request}) => {
                const {pageNumber} = params
                const {searchParams} = new URL(request.url)
                const issueTypeId = searchParams.get('issueTypeId')
                const pageSize = searchParams.get('pageSize')

                if (pageNumber && pageSize) {
                    const topics = await listTopics({
                        issueTypeId,
                        pageNumber,
                        pageSize,
                        state,
                    })

                    return {topics}
                }
                else {
                    const to = makePath(
                        fullPath,

                        {
                            pageNumber: 1,
                            ...params
                        },

                        {
                            pageSize: 10,
                            ...Object.fromEntries(searchParams.entries()),
                        }
                    )

                    return redirect(to)
                }
            },

            path: routePath,
        }
    })
}

export const indexPath = 'latest'
