import {useNavigate, useNavigation, useSubmit} from 'react-router-dom'
import {Button} from 'antd'
import PostPermission from '../PostPermission.jsx'
import {StarOutlined, StarFilled} from '@ant-design/icons'

export default function ButtonFavorite({isFavorited, questionId, ...props}) {
    const navigate = useNavigate()
    const navigation = useNavigation()
    const submit = useSubmit()

    const handleClick = () => {
        const action = isFavorited ? 'unfavorite' : 'favorite'
        const fd = new FormData()
        fd.set('issueMainId', questionId)
        submit(fd, {action, method: 'PUT'})
    }

    const okElement = (
        <Button
            icon={isFavorited ? <StarFilled/> : <StarOutlined/>}
            type="text"
            loading={'submitting' === navigation.state}
            onClick={handleClick}
            {...props}
        >
            {isFavorited ? '取消收藏' : '收藏'}
        </Button>
    )

    const handleLogin = () => {
        navigate('/login?r=-1')
    }

    const unknownElement = (
        <Button
            icon={<StarOutlined/>}
            type="text"
            onClick={handleLogin}
            {...props}
        >
            收藏
        </Button>
    )

    return (
        <PostPermission
            every={['favorite']}
            okElement={okElement}
            unknownElement={unknownElement}
        />
    )
}
