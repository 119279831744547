import {css} from '@emotion/react'

import {
    BORDER_RADIUS_SMALL,
    FONT_SIZE_LARGE,
    FONT_SIZE_SECONDARY,
    GAP_MEDIUM,
} from '@/styles/config.mjs'

const cssHeader = css({
})

const cssH1 = css({
    marginTop: 0,
})

const cssGuide = css({
    backgroundColor: '#ebf4fb',
    fontSize: FONT_SIZE_SECONDARY,
    lineHeight: 1.6,
    padding: `0 ${GAP_MEDIUM}px`,
    border: '1px solid #a6ceed',
    borderRadius: BORDER_RADIUS_SMALL,
})

const cssH2 = css({
    fontWeight: 400,
    fontSize: FONT_SIZE_LARGE,
})

export default function Header(props) {
    return (
        <div
            css={cssHeader}
            {...props}
        >
            <h1 css={cssH1}>提出一个问题</h1>

            <div css={cssGuide}>
                <h2 css={cssH2}>如何写一个好问题：</h2>
                <ol>
                    <li>为你的问题选择合适的类型以便相关人员处理</li>
                    <li>用一句话概括你的问题作为标题</li>
                    <li>详细描述你的问题</li>
                    <li>详细描述你为解决问题作出的尝试</li>
                    <li>详细描述你期望的结果和实际的结果</li>
                    {/*<li>为你的问题增加「标签」以便更多人发现你的问题</li>*/}
                    <li>检查你的提问，确认无误后提交</li>
                </ol>
            </div>
        </div>
    )
}
