import {Outlet} from 'react-router-dom'
import {css} from '@emotion/react'
import {DIVIDER, GAP_MEDIUM, GAP_SMALL} from '@/styles/config.mjs'
import ButtonAsk from './ButtonAsk.jsx'
import SelectQuestionCategory from './SelectQuestionCategory.jsx'
import SelectQuestionType from './SelectQuestionType.jsx'
import {theme} from 'antd'


export default function Questions(props) {
    const {useToken} = theme
    const {token} = useToken()

    const cssMain = css({
        backgroundColor: '#fff',
    })

    const cssHead = css({
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '0 16px',
        borderTop: `1.5px solid ${token.colorPrimary}`,
        borderBottom: DIVIDER,
        // gridTemplateColumns: '1fr auto 200px',
        // gap: GAP_SMALL,
        // padding: GAP_MEDIUM,
    })

    const cssHeadRightSub = css({
        display: 'flex',
        alignItems: 'center',

    })

    const cssAsk = css({
        width: 100,
        marginLeft: 16,
        justifySelf: 'center',
        textAlign: 'center',
    })

    return (
        <main
            css={cssMain}
            {...props}
        >
            <div css={cssHead}>
                <SelectQuestionCategory/>
                <div css={cssHeadRightSub}>
                    <SelectQuestionType/>
                    <ButtonAsk css={cssAsk}/>
                </div>
            </div>

            <Outlet/>
        </main>
    )
}
