import {css} from '@emotion/react'
import cssBlock from '@/styles/Block.mjs'
import {GAP_MEDIUM} from '@/styles/config.mjs'
import Account from './Account.jsx'
import Logo from './Logo.jsx'
import Nav from './Nav.jsx'
import Search from './Search.jsx'

const cssHeader = css({
    display: 'grid',
    gridTemplateColumns: 'auto 1fr minmax(auto, 400px) auto',
    gridTemplateRows: '100%',
    alignItems: 'center',
    gap: GAP_MEDIUM,
    height: 52,
    backgroundColor: '#fff',
})

export default function Header(props) {
    return (
        <header
            css={[cssBlock, cssHeader]}
            {...props}
        >
            <Logo />
            <Nav />
            <Search />
            <Account />
        </header>
    )
}
