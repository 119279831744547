import {Link} from 'react-router-dom'
import {css} from '@emotion/react'
import {useAuth} from '@/hooks/auth.jsx'
import {Avatar, Badge, Divider, theme, Typography} from "antd";
import {FONT_SIZE_SECONDARY, GAP_MEDIUM, GAP_SMALL, TEXT_COLOR_TRIVIAL,} from '@/styles/config.mjs'

import {cssLink} from '@/styles/Link.mjs'
import defaultAvatar from '@/assets/default_avatar.png'

const {Text} = Typography;

const cssUser = css({
    display: 'flex',
    alignItems: 'center',
    gap: GAP_MEDIUM,
})
const cssUserIntro = css({
    display: 'flex',
    flexDirection: 'column',
    gap: GAP_MEDIUM,
})

const User = ({id, nickname, avatar, ...props}) => {
    return (
        <div
            css={cssUser}
            {...props}
        >
            <Avatar size={48} src={avatar || defaultAvatar}/>
            <div css={cssUserIntro}>
                <Text>
                    {nickname}
                </Text>
                <Text type='secondary'>
                    暂无介绍
                </Text>
            </div>
        </div>
    )
}

const cssItem = css({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: GAP_SMALL,
})

const cssItemTitle = css({
    color: TEXT_COLOR_TRIVIAL,
    fontSize: FONT_SIZE_SECONDARY,
})

const cssItemCount = css({
    fontSize: 20,
    fontWeight: 'bold',
})

const Item = ({count, title, to, ...props}) => {
    return (
        <Link
            css={[cssLink, cssItem]}
            to={to}
            {...props}
        >
            <div css={cssItemTitle}>{title}</div>
            <div css={cssItemCount}>{count}</div>
        </Link>
    )
}

const Ribbon = ({
                    adminFlag,
                    officialCertificationFlag,
                    children,
                    ...props
                }) => {
    const ribbonText = adminFlag ? '管理员' : (officialCertificationFlag ? '官方人员' : null)

    if (ribbonText) {
        return (
            <Badge.Ribbon text={ribbonText} color="volcano">
                {children}
            </Badge.Ribbon>
        )
    } else {
        return children;
    }
}

// const cssMine = css({
//     display: 'grid',
//     gridTemplateColumns: '1fr 1fr 1fr 1fr'
// })

export default function Mine(props) {
    const {useToken} = theme
    const {token} = useToken()

    const {user} = useAuth()

    if (!user) {
        return null
    }

    const {
        id,
        nickname,
        avatar,
        adminFlag,
        officialCertificationFlag,
        userCustomerCountDto: {
            favoriteCount,
            followCount,
            issueCommentCount,
            issueMainCount,
        },
    } = user

    const cssMine = css({
        display: 'flex',
        flexDirection: 'column',
        padding: GAP_MEDIUM,
        backgroundColor: 'white',
        borderTop: `1.5px solid ${token.colorPrimary}`,
    })

    const cssMineItems = css({
        display: 'grid',
        gridTemplateColumns: '1fr 1fr 1fr 1fr'
    })

    return (
        <Ribbon adminFlag={adminFlag} officialCertificationFlag={officialCertificationFlag}>
            <div css={cssMine} {...props}>
                <User id={id} nickname={nickname} avatar={avatar}/>
                <Divider/>
                <div css={cssMineItems}>
                    <Item
                        count={favoriteCount}
                        title="收藏"
                        to=""
                    />

                    <Item
                        count={followCount}
                        title="关注"
                        to=""
                    />

                    <Item
                        count={issueMainCount}
                        title="提问"
                        to=""
                    />

                    <Item
                        count={issueCommentCount}
                        title="回答"
                        to=""
                    />
                </div>
            </div>
        </Ribbon>
    )
    // return (
    //     <Card
    //         title="我的"
    //         {...props}
    //     >
    //         <div css={cssMine}>
    //             <Item
    //                 count={favoriteCount}
    //                 title="收藏"
    //                 to=""
    //             />
    //
    //             <Item
    //                 count={followCount}
    //                 title="关注"
    //                 to=""
    //             />
    //
    //             <Item
    //                 count={issueMainCount}
    //                 title="提问"
    //                 to=""
    //             />
    //
    //             <Item
    //                 count={issueCommentCount}
    //                 title="回答"
    //                 to=""
    //             />
    //         </div>
    //     </Card>
    // )
}
