const ltrim = (s) => s.replace(/^\//, '')
const rtrim = (s) => s.replace(/\/$/, '')
const trim = (s) => s.replace(/^\/|\/$/g, '')

export const join = (...urlParts) => {
    if (0 === urlParts.length) {
        return ''
    }
    else if (1 === urlParts.length) {
        return urlParts[0]
    }
    else {
        const firstPart = rtrim(urlParts[0])
        const lastPart = ltrim(urlParts[urlParts.length - 1])
        const middleParts = urlParts.slice(1, -1).map(trim)

        return [firstPart, ...middleParts, lastPart]
            .filter((s) => s)
            .join('/')
    }
}
