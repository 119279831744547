import {
    useLoaderData,
    useNavigate,
    useParams,
    useSearchParams,
} from 'react-router-dom'

import {css} from '@emotion/react'
import {Pagination} from 'antd'
import {useUpdatePath} from '@/scripts/route.mjs'
import {DIVIDER, GAP_MEDIUM} from '@/styles/config.mjs'
import Question from './Question.jsx'

const cssList = css({
    '&>*': {
        borderBottom: DIVIDER,

        '&:first-child': {
            borderTop: DIVIDER,
        },
    }
})

const cssPagination = css({
    marginTop: GAP_MEDIUM,
})

export default function QuestionList({path, ...props}) {
    const {topics} = useLoaderData()
    const navigate = useNavigate()
    const {pageNumber} = useParams()
    const [searchParams] = useSearchParams()
    const pageSize = searchParams.get('pageSize')
    const updatePath = useUpdatePath()

    const items = (topics.data ?? []).map(
        (data) => (
            <Question
                key={data.id}
                data={data}
            />
        )
    )

    const handleChangePagination = (pageNumber, pageSize) => {
        const newPath = updatePath(path, {pageNumber}, {pageSize})
        navigate(newPath)
    }

    return (
        <>
            <div
                css={cssList}
                {...props}
            >
                {items}
            </div>

            <Pagination
                css={cssPagination}
                current={Number(pageNumber)}
                hideOnSinglePage
                pageSize={Number(pageSize)}
                showSizeChanger
                total={topics.totalRecord}
                onChange={handleChangePagination}
            />
        </>
    )
}
