import {
    generatePath,
    redirect,
    useParams,
    useSearchParams,
} from 'react-router-dom'

import {useAuth} from '@/hooks/auth.jsx'

export const makePath = (pattern, urlParams, searchParams, hash) => {
    let path = generatePath(pattern, urlParams)
    const qs = new URLSearchParams()

    for (const [k, v] of Object.entries(searchParams)) {
        qs.set(k, v)
    }

    if (0 < qs.size) {
        path += `?${qs}`
    }

    if (hash) {
        path += `#${hash}`
    }

    return path
}

export const onLogin = () => {
    const {searchParams} = new URL(location.href)
    const r = searchParams.get('r')

    if ('-1' === r) {
        history.back()
    }
    else if (r) {
        history.replaceState(history.state, null, r)
    }
    else {
        history.replaceState(history.state, null, '/')
    }
}

export const redirectToIndex = ({indexPath, params, path, request}) => {
    const routePath = generatePath(path, params)
    const {pathname, search} = new URL(request.url)

    if (
        pathname === routePath ||
        pathname === `${routePath}/`
    ) {
        return redirect(indexPath + search)
    }
    else {
        return null
    }
}

export const useAuthedLoader = (loader) => {
    const {user} = useAuth()

    return ({params, request}) => {
        // 登录完成后跳转至目标页面执行本函数时，用户信息是旧值，
        // 使用 history.replaceState 可以强制路由使用新值
        if ('/login' === location.pathname) {
            history.replaceState(history.state, null, request.url)
            return null
        }
        else if (user) {
            if (loader) {
                return loader({params, request})
            }
            else {
                return null
            }
        }
        else {
            const r = request.url.slice(location.origin.length)
            return redirect(`/login?r=${encodeURIComponent(r)}`)
        }
    }
}

export const useUpdatePath = () => {
    const params = useParams()
    const [searchParams] = useSearchParams()

    return (pattern, urlParams, searchParamsObj) => {
        return makePath(
            pattern,
            {...params, ...urlParams},
            {...Object.fromEntries(searchParams), ...searchParamsObj}
        )
    }
}
