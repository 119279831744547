import {useAuthedLoader} from '@/scripts/route.mjs'
import Ask from './Ask.jsx'

const path = 'ask'

export default () => {
    return {
        element: <Ask />,
        loader: useAuthedLoader(),
        path,
    }
}
