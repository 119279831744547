import {css} from '@emotion/react'
import {CKEditor} from '@ckeditor/ckeditor5-react'
import Editor from 'ckeditor5-custom-build/build/ckeditor'

const cssEditor = css({
    display: 'grid',
    minHeight: 300,
    overflow: 'hidden',

    '& .ck-editor': {
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
        overflow: 'hidden',
    },

    '& .ck-editor__main': {
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
        overflow: 'hidden',
    },

    '& .ck-editor__editable': {
        flexGrow: 1,
        overflow: 'auto',
    },

    '& .ck-content .table': {
        margin: '0.9em',
    },
})

const cssEditorDisabled = css({
    '& .ck-editor__top': {
        display: 'none',
    },
})

const cssEditorReadOnly = css({
    minHeight: 0,

    '& .ck-content': {
        padding: 0,
    },

    '& .ck-editor__top': {
        display: 'none',
    },

    '& .ck.ck-editor__main>.ck-editor__editable:not(.ck-focused)': {
        border: 'none',
    },
})

const config = {
    removePlugins: [],
}

export default ({
    className,
    style,
    disabled,
    readOnly,
    value,
    onChange,
    ...props
}) => {
    const handleChange = (event, editor) => {
        const value = editor.getData()
        onChange?.(value)
    }

    const csss = [cssEditor]

    if (disabled) {
        csss.push(cssEditorDisabled)
    }
    else if (readOnly) {
        csss.push(cssEditorReadOnly)
    }

    return (
        <div
            className={className}
            style={style}
            css={csss}
        >
            <CKEditor
                config={config}
                data={value ?? ''}
                disabled={disabled || readOnly}
                editor={Editor}
                onChange={handleChange}
                {...props}
            />
        </div>
    )
}
