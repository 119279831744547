import useHttp from './useHttp.mjs'

export default () => {
    const http = useHttp()

    return {
        oneUserCustomer(args) {
            return http.get('/userCustomer/oneUserCustomer', args)
        },

        listQuestionsAsked(args) {
            return http.get('/issue/pagingIssueMainAdd', args)
        },

        listQuestionsCommented(args) {
            return http.get('/issue/pagingIssueMainComment', args)
        },

        listQuestionsFavorited(args) {
            return http.get('/issue/pagingIssueMainFavorite', args)
        },

        listQuestionsFollowed(args) {
            return http.get('/issue/pagingIssueMainFollow', args)
        },
    }
}
