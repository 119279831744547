import {Link} from 'react-router-dom'
import {css} from '@emotion/react'
import defaultAvatar from '@/assets/default_avatar.png'
import {GAP_MEDIUM, GAP_SMALL} from '@/styles/config.mjs'

import {cssLink} from '@/styles/Link.mjs'

const cssUser = css({
    display: 'inline-flex',
    alignItems: 'center',
    gap: GAP_SMALL,
})

const cssAvatar = css({
    width: '1.6em',
    height: '1.6em',
})

export default function User({avatar, name, userId, ...props}) {
    return (
        <Link
            css={[cssLink, cssUser]}
            to={`/users/${userId}`}
            {...props}
        >
            {
                undefined !== avatar &&
                <img
                    css={cssAvatar}
                    alt="avatar"
                    src={avatar || defaultAvatar}
                    title={name ? '' : name}
                />
            }

            {name}
        </Link>
    )
}
