import {Radio} from 'antd'
import useBizTable from '@/hooks/useBizTable.mjs'
import Card from './Card.jsx'

export default function Type({value, onChange, ...props}) {
    const issueTypes = useBizTable('issueTypeDtos')

    const options = issueTypes.map(
        ({id: value, name: label}) => ({label, value})
    )

    const handleChange = ({target: {value}}) => {
        onChange(value)
    }

    return (
        <Card
            title="类型"
            subTitle="选择正确的类型有助于相关人员更快的解决问题"
            {...props}
        >
            <Radio.Group
                options={options}
                optionType="button"
                value={value}
                onChange={handleChange}
            />
        </Card>
    )
}
