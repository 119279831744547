import {css} from '@emotion/react'
import {FONT_SIZE_TRIVIAL, TEXT_COLOR_SECONDARY} from '@/styles/config.mjs'

const cssAction = css({
    display: 'flex',
    alignSelf: 'flex-end',
    color: TEXT_COLOR_SECONDARY,
    fontSize: FONT_SIZE_TRIVIAL,
})

export default function Action({action, datetime, ...props}) {
    return (
        <div
            css={cssAction}
            {...props}
        >
            发布于 {datetime}
        </div>
    )
}
