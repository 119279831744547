import {useState} from 'react'
import {useNavigate, useNavigation, useSubmit} from 'react-router-dom'
import {css} from '@emotion/react'
import {Button} from 'antd'
import TextArea from '@/components/Form/TextArea.jsx'
import {GAP_MEDIUM} from '@/styles/config.mjs'
import PostPermission from '../PostPermission.jsx'

const cssWriteComment = css({
    display: 'grid',
    gridTemplateColumns: '1fr auto',
    gap: GAP_MEDIUM,
})

export default function WriteComment({
    issueMainId,
    targetCommentId,
    targetUserId,
    ...props
}) {
    const navigate = useNavigate()
    const navigation = useNavigation()
    const submit = useSubmit()
    const [formData, setFormData] = useState({})

    const handleChangeDetail = (content) => {
        setFormData((formData) => ({...formData, content}))
    }

    const handleSubmit = () => {
        const fd = new FormData()
        fd.set('issueMainId', issueMainId)
        fd.set('targetCommentId', targetCommentId)
        fd.set('targetUserId', targetUserId)

        for (const [k, v] of Object.entries(formData)) {
            fd.set(k, v)
        }

        submit(fd, {action: 'comments', method: 'POST'})
        // TODO: 提交成功才清除表单内容
        setFormData({})
    }

    const handleLogin = () => {
        navigate('/login?r=-1')
    }

    const okElement = (
        <div
            css={cssWriteComment}
            {...props}
        >
            <TextArea
                autoSize
                disabled={'submitting' === navigation.state}
                placeholder="写下你的评论"
                rows={1}
                value={formData.content}
                onChange={handleChangeDetail}
            />

            <Button
                disabled={! formData.content}
                loading={'submitting' === navigation.state}
                type="primary"
                onClick={handleSubmit}
            >
                回复
            </Button>
        </div>
    )

    const unknownElement = (
        <div
            css={cssWriteComment}
            {...props}
        >
            <TextArea
                autoSize
                disabled={'submitting' === navigation.state}
                placeholder="写下你的评论"
                rows={1}
                onChange={handleChangeDetail}
            />

            <Button
                loading={'submitting' === navigation.state}
                type="primary"
                onClick={handleLogin}
            >
                回复
            </Button>
        </div>
    )

    return (
        <PostPermission
            every={['reply']}
            okElement={okElement}
            unknownElement={unknownElement}
        />
    )
}
