import RichTextEditor from '@/components/RichTextEditor/RichTextEditor.jsx'
import Card from './Card.jsx'

export default function Detail({value, onChange, ...props}) {
    return (
        <Card
            title="细节"
            subTitle="详细描述你的问题、你为解决问题所做的尝试、你期望的结果以及实际的结果"
            {...props}
        >
            <RichTextEditor
                value={value}
                onChange={onChange}
            />
        </Card>
    )
}
